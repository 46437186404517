<template>
  <div id="job_application_form">
    <div id="job_applications_header" :style="{ background: draft_employee.job_listing.color }">
      <h1 :style="{ color: isColorLight(draft_employee.job_listing.color) ? '#231F20' : '#fff' }">Job Application</h1>
      <p :style="{ color: isColorLight(draft_employee.job_listing.color) ? '#231F20' : '#fff' }">
        Powered by

        <img
          v-if="isColorLight(draft_employee.job_listing.color)"
          src="../../../app/assets/images/home/logo_dark.svg"
          alt="Payboy"
        />
        <img v-else src="../../../app/assets/images/home/logo_white.svg" alt="Payboy" />
      </p>
    </div>
    <div class="container">
      <div id="job_applications_container">
        <br />

        <div class="card-panel job_applications_card">
          <img
            v-if="draft_employee.job_listing.logo_option === 'company' && companyLogoUrl"
            :src="companyLogoUrl"
            class="company-logo"
            alt="Logo"
          />
          <img
            v-else-if="!companyLogoUrl && organizationLogoUrl"
            :src="organizationLogoUrl"
            class="company-logo"
            alt="Logo"
          />
          <img
            v-else-if="companyLogoUrl && !organizationLogoUrl"
            :src="companyLogoUrl"
            class="company-logo"
            alt="Logo"
          />
          <img v-else src="../../../app/assets/images/home/logo.svg" class="company-logo" alt="Logo" />
          <br />
          <h4 style="margin-bottom: 10px;">Description</h4>
          <div><strong>Organisation: </strong>{{ draft_employee.organisation_name }}</div>
          <div><strong>Job Title: </strong> {{ draft_employee.job_listing.job_title }}</div>
          <br />
          <p v-html="draft_employee.job_listing.description"></p>
        </div>
        <div>
          <materialize-form
            class="form-font-size"
            v-model="draft_employee"
            action="/job_applications"
            modelName="draft_employee"
          >
            <!-- BASIC INFORMATION -->
            <div class="card-panel job_applications_card">
              <h4>Basic Info</h4>
              <div class="form-inputs row">
                <materialize-input
                  label="Name (as per NRIC)"
                  v-model="draft_employee.name"
                  :errors="draft_employee.errors.name"
                  :required="true"
                  class="col s12 m6"
                />
                <materialize-input
                  label="Email"
                  v-model="draft_employee.email"
                  :errors="draft_employee.errors.email"
                  :required="true"
                  class="col s12 m6"
                />
                <materialize-input
                  v-if="draft_employee.job_listing.gender_enabled"
                  label="Gender"
                  v-model="draft_employee.gender"
                  :errors="draft_employee.errors.gender"
                  placeholder="Select an option"
                  :options="genderTypes"
                  type="select"
                  :required="draft_employee.job_listing.gender_required"
                  class="col s12 m4"
                />
                <materialize-input
                  v-if="draft_employee.job_listing.home_number_enabled"
                  label="Home Number"
                  v-model="draft_employee.home_number"
                  :errors="draft_employee.errors.home_number"
                  :required="draft_employee.job_listing.home_number_required"
                  type="number"
                  class="col s12 m4"
                />
                <materialize-input
                  label="Mobile Number"
                  v-model="draft_employee.mobile_number"
                  :errors="draft_employee.errors.mobile_number"
                  :required="true"
                  type="number"
                  class="col s12 m4"
                />
                <materialize-input
                  v-if="draft_employee.job_listing.birth_date_enabled"
                  label="Date of Birth"
                  v-model="draft_employee.birth_date"
                  :errors="draft_employee.errors.birth_date"
                  placeholder="Select date"
                  :required="draft_employee.job_listing.birth_date_required"
                  class="col s12 m4"
                  type="date"
                  :flatpickrOptions="{ mode: 'single', static: true }"
                />
                <materialize-input
                  v-if="draft_employee.job_listing.nationality_enabled"
                  label="Nationality"
                  v-model="draft_employee.nationality"
                  :errors="draft_employee.errors.nationality"
                  placeholder="Select an option"
                  :options="nationalityTypes"
                  type="select"
                  :required="draft_employee.job_listing.nationality_required"
                  class="col s12 m4"
                />
                <materialize-input
                  v-if="draft_employee.job_listing.citizenship_enabled"
                  label="Citizenship"
                  v-model="draft_employee.citizenship"
                  :errors="draft_employee.errors.citizenship"
                  placeholder="Select an option"
                  :options="citizenshipTypes"
                  type="select"
                  :required="draft_employee.job_listing.citizenship_required"
                  class="col s12 m4"
                />
              </div>
              <div class="form-inputs row">
                <materialize-input
                  v-show="draft_employee.citizenship == 'Permanent Resident'"
                  label="PR Obtained Date"
                  v-model="draft_employee.pr_obtain_date"
                  :errors="draft_employee.errors.pr_obtain_date"
                  placeholder="Select date"
                  type="date"
                  :required="true"
                  class="col s12 m4"
                  :flatpickrOptions="{ mode: 'single', static: true }"
                />
                <materialize-input
                  v-show="draft_employee.citizenship == 'Permanent Resident'"
                  label="Re-Entry Permit Expiry Date"
                  v-model="draft_employee.pr_expiry_date"
                  :errors="draft_employee.errors.pr_expiry_date"
                  placeholder="Select date"
                  type="date"
                  :required="true"
                  class="col s12 m4"
                  :flatpickrOptions="{ mode: 'single', static: true }"
                />
              </div>
              <div class="form-inputs row">
                <materialize-input
                  v-if="draft_employee.job_listing.marital_status_enabled"
                  label="Marital Status"
                  v-model="draft_employee.marital_status"
                  :errors="draft_employee.errors.marital_status"
                  placeholder="Select an option"
                  :options="maritalStatusTypes"
                  type="select"
                  :required="draft_employee.job_listing.marital_status_required"
                  class="col s12 m4"
                />
                <materialize-input
                  v-if="draft_employee.job_listing.race_enabled"
                  label="Race"
                  v-model="draft_employee.race"
                  :errors="draft_employee.errors.race"
                  placeholder="Select an option"
                  :options="raceTypes"
                  type="select"
                  :required="draft_employee.job_listing.race_required"
                  class="col s12 m4"
                />
                <materialize-input
                  v-if="draft_employee.job_listing.religion_enabled"
                  label="Religion"
                  v-model="draft_employee.religion"
                  :errors="draft_employee.errors.religion"
                  placeholder="Select an option"
                  :options="religionTypes"
                  type="select"
                  :required="draft_employee.job_listing.religion_required"
                  class="col s12 m4"
                />
              </div>

              <!-- PRIMARY IDENTITY -->
              <div class="card-panel" v-if="draft_employee.job_listing.identity_number_enabled">
                <h5>Primary Identity</h5>
                <br />
                <div class="form-inputs row">
                  <materialize-input
                    v-if="draft_employee.job_listing.identity_number_enabled"
                    label="Identity Type"
                    v-model="draft_employee.id_type"
                    :errors="draft_employee.errors.id_type"
                    placeholder="Select an option"
                    :options="idTypes"
                    type="select"
                    :required="draft_employee.job_listing.identity_number_required"
                    class="col s12 m4"
                  />
                  <materialize-input
                    v-if="draft_employee.job_listing.identity_number_enabled"
                    label="Primary Identity Number"
                    v-model="draft_employee.nric"
                    :errors="draft_employee.errors.nric"
                    :required="draft_employee.job_listing.identity_number_required"
                    class="col s12 m4"
                  />
                </div>
              </div>

              <!-- ADDRESS -->
              <div class="card-panel" v-if="draft_employee.job_listing.address_enabled">
                <h5>Address</h5>
                <br />
                <div class="form-inputs row">
                  <materialize-input
                    label="Country of Address"
                    v-model="draft_employee.address_country_code"
                    :errors="draft_employee.errors.address_country_code"
                    placeholder="Select an option"
                    :options="countries"
                    type="select"
                    :required="draft_employee.job_listing.address_required"
                    @input="onAreaCodesUpdate"
                    class="col s12 m4"
                  />
                  <materialize-input
                    label="Postal Code"
                    v-model="draft_employee.postal_code"
                    :errors="draft_employee.errors.postal_code"
                    :required="draft_employee.job_listing.address_required"
                    type="number"
                    class="col s12 m4"
                  />
                  <materialize-input
                    label="Address Line 1"
                    v-model="address_line_1"
                    :errors="draft_employee.errors.address_line_1"
                    @change="onAddressUpdate"
                    :required="draft_employee.job_listing.address_required"
                    class="col s12 m4"
                  />
                  <materialize-input label="Address Line 2" v-model="address_line_2" class="col s12 m4" />
                  <materialize-input label="Address Line 3" v-model="address_line_3" class="col s12 m4" />
                </div>
              </div>

              <!-- EMERGENCY CONTACT -->
              <div class="card-panel" v-if="draft_employee.job_listing.emergency_contact_enabled">
                <h5>Emergency Contact</h5>
                <br />
                <div class="form-inputs row">
                  <materialize-input
                    label="Name (as per NRIC)"
                    v-model="draft_employee.family_member_name"
                    :errors="draft_employee.errors.family_member_name"
                    :required="draft_employee.job_listing.emergency_contact_required"
                    class="col s12 m4 l4"
                  />
                  <materialize-input
                    label="Relationship"
                    v-model="draft_employee.family_member_relationship"
                    :errors="draft_employee.errors.family_member_relationship"
                    :options="relationshipOptions"
                    type="select"
                    :required="draft_employee.job_listing.emergency_contact_required"
                    class="col s12 m4 l4"
                  />
                  <materialize-input
                    label="Gender"
                    v-model="draft_employee.family_member_gender"
                    :errors="draft_employee.errors.family_member_gender"
                    placeholder="Select an option"
                    :options="genderTypes"
                    type="select"
                    :required="draft_employee.job_listing.emergency_contact_required"
                    class="col s12 m4 l4"
                  />
                </div>
                <div class="row">
                  <materialize-input
                    label="Mobile Number"
                    v-model="draft_employee.family_member_phone_number"
                    :errors="draft_employee.errors.family_member_phone_number"
                    :required="draft_employee.job_listing.emergency_contact_required"
                    type="number"
                    class="col s12 m4"
                  />
                  <materialize-input
                    label="Residential Address"
                    v-model="draft_employee.family_member_address"
                    :errors="draft_employee.errors.family_member_address"
                    :required="draft_employee.job_listing.emergency_contact_required"
                    class="col s12 m8"
                  />
                </div>
              </div>

              <!-- BANK DETAILS -->
              <div class="card-panel" v-if="draft_employee.job_listing.bank_details_enabled">
                <h5>Bank Details</h5>
                <br />
                <div class="form-inputs row">
                  <materialize-input
                    label="Bank Name"
                    v-model="draft_employee.bank_name"
                    :errors="draft_employee.errors.bank_name"
                    placeholder="Select an option"
                    :options="bankOptions"
                    type="select"
                    :required="draft_employee.job_listing.bank_details_required"
                    class="col s12 m4"
                  />
                  <materialize-input
                    label="Bank Account Number"
                    v-model="draft_employee.bank_account_number"
                    :errors="draft_employee.errors.bank_account_number"
                    :required="draft_employee.job_listing.bank_details_required"
                    class="col s12 m4"
                  />
                </div>
              </div>
            </div>
            <div class="card-panel job_applications_card">
              <div v-for="(question, question_index) in draft_employee.job_listing.questions" :key="question_index">
                <form-field
                  v-bind:style="{ margin: question.field_type.includes('box') ? '0' : '1.5rem' }"
                  v-bind:field="question"
                  v-bind:answer="draft_employee.answers.find((answer) => answer.question_id === question.id)"
                  v-bind:canEdit="true"
                />
              </div>
            </div>

            <!-- UPLOAD DOCUMENTS -->
            <!-- <div class="card-panel">
                    <div v-for="(document, i) in draft_employee.documents" v-if="!document._destroy" class="col s12 document card-panel">
                      <h5>{{document.title}} ({{document.description}})</h5>
                      <div class="row">
                        <materialize-input class="col s12"
                            label="Select File"
                            placeholder=".pdf, .docx, .xlsx, .pptx, .txt, .jpg, .png"
                            type="file"
                            v-model="document.file"
                            :errors="document.errors.file"
                            :fileName.sync="document.file_file_name"
                            :fileDelete.sync="document.file_delete"
                            :required="true"/>
                        <p class="col s12 instruction">
                          <small><abbr>*</abbr> Required fields</small>
                        </p>
                      </div>
                    </div>
            </div> -->

            <!-- EDUCATION & OTHER QUALIFICATIONS -->
            <!-- <div class="card-panel">
              <h5>Educational and other professional qualifications</h5>
              <br>
              <div class="form-inputs row">
                <materialize-input
                  label="From"
                  v-model="draft_employee.qualification_start_date"
                  placeholder="Select date"
                  class="col s12 m4"
                  type="date"
                />
                <materialize-input
                  label="To"
                  v-model="draft_employee.qualification_end_date"
                  placeholder="Select date"
                  class="col s12 m4"
                  type="date"
                />
                <materialize-input
                    label="Name of Institution/Country"
                    v-model="draft_employee.qualification_institution_name"
                    class="col s12 m4"
                  />
                <materialize-input
                  label="Highest Qualification Attained"
                  v-model="draft_employee.qualification_highest_attained"
                  placeholder="Select an option"
                  :options="nsOptions"
                  type="select"
                  class="col s12 m4"
                />
              </div>
            </div> -->

            <!-- EMPLOYMENT HISTORY -->
            <!-- <div class="card-panel">
              <h5>Employment History</h5>
              <br>
              <div class="form-inputs row">
                <materialize-input
                  label="Company Name"
                  v-model="draft_employee.previous_company_name"
                  class="col s12 m4"
                />
                <materialize-input
                  label="From"
                  v-model="draft_employee.previous_job_start_date"
                  placeholder="Select date"
                  class="col s12 m4"
                  type="date"
                />
                <materialize-input
                  label="To"
                  v-model="draft_employee.previous_job_end_date"
                  placeholder="Select date"
                  class="col s12 m4"
                  type="date"
                />
                <materialize-input
                  label="Current/Last Drawn Salary"
                  v-model="draft_employee.previous_salary"
                  class="col s12 m4"
                />
                <materialize-input
                  label="Allowance & Bonus (if any)"
                  v-model="draft_employee.previous_allowance"
                  class="col s12 m4"
                />
                <materialize-input
                  label="Job Title"
                  v-model="draft_employee.previous_job_title"
                  class="col s12 m4"
                />
                <materialize-input
                  label="Reasons for Leaving"
                  v-model="draft_employee.previous_reasons"
                  class="col s12 m4"
                />
              </div>
            </div> -->

            <!-- <div class="card-panel">
              <h5>Declaration</h5>
              <br>
              <materialize-input
                class="col s12"
                label="Have you any objection to reference being made to your present / previous employer?"
                v-model="draft_employee.declaration"
                type="checkbox"
              />
              <materialize-input
                class="col s12"
                label="Have you been discharged or dismissed from the service of your previous employers?"
                v-model="draft_employee.declaration"
                type="checkbox"
              />
              <materialize-input
                class="col s12"
                label="Have you been or are you suffering from any disease / illness / major medical condition in the last 6 months?"
                v-model="draft_employee.declaration"
                type="checkbox"
              />
              <materialize-input
                class="col s12"
                label="Are you currently on any medication?"
                v-model="draft_employee.declaration"
                type="checkbox"
              />
              <materialize-input
                class="col s12"
                label="Have you taken any illicit drugs?"
                v-model="draft_employee.declaration"
                type="checkbox"
              />
              <materialize-input
                class="col s12"
                label="Have you been convicted in a court of law in any country or detained under the provisions of any written law?"
                v-model="draft_employee.declaration"
                type="checkbox"
              />
              <materialize-input
                class="col s12"
                label="Have you ever been declared a bankrupt?"
                v-model="draft_employee.declaration"
                type="checkbox"
              />
              <materialize-input
                class="col s12"
                label="Do you have any share in any business undertaking other than that in a public-listed company?"
                v-model="draft_employee.declaration"
                type="checkbox"
              />
              <materialize-input
                class="col s12"
                label="Are you holding a directorship or other appointment in any company?"
                v-model="draft_employee.declaration"
                type="checkbox"
              />
            </div>
            <br>
            <br>
            <div class="row">
              <materialize-input
                class="col s12"
                :label='`I declare that all the information given herein is true and correct.
                  I understand that a misrepresentation or omission of facts will be sufficient cause for cancellation of consideration for employment or dismissal
                  from the Company’s service if I have been employed. I understand that completing this application is not a promise of employment from the Group or
                  its subsidiaries. I authorize ${draft_employee.company_name} and/or its subsidiaries to confirm any of the statements above and also give
                  the company the right to investigate my background at any time. I hereby declare that all information provided in this application and on the
                  attached resume is true, complete and accurate. I understand that the submission of false information or misrepresentation in this application,
                  may result in the immediate termination of my employment should I be employed by the Company.`'
                v-model="draft_employee.declaration"
                type="checkbox"
              />
            </div> -->
          </materialize-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterializeForm from '../components/materialize_form';
import MaterializeInput from '../components/materialize_input';
import Selectize from '../components/selectize.vue';
import FormField from '../components/form_field.vue';
import { debounce } from 'lodash';

export default {
  components: { MaterializeForm, MaterializeInput, Selectize, FormField },
  data() {
    return {
      geocoder: new google.maps.Geocoder(),
      address_line_1: '',
      address_line_2: '',
      address_line_3: '',
      draft_employee: gon.draft_employee,
      idTypes: gon.form_options.id_types,
      maritalStatusTypes: gon.form_options.marital_status_types,
      genderTypes: gon.form_options.gender_types,
      religionTypes: gon.form_options.religion_types,
      raceTypes: gon.form_options.race_types,
      nationalityTypes: gon.form_options.nationality_types,
      citizenshipTypes: gon.form_options.citizenship_types,
      countries: gon.form_options.countries,
      organizationOptions: gon.form_options.organization_options,
      departmentOptions: gon.form_options.department_options,
      positionOptions: gon.form_options.position_options,
      employmentTypes: gon.form_options.employment_types,
      paymentMethodOptions: gon.form_options.payment_methods,
      currencyOptions: gon.form_options.currencies,
      bankOptions: gon.form_options.banks,
      relationshipOptions: gon.form_options.family_member_relationships,
      companyLogoUrl: gon.form_options.company_thumbnail,
      organizationLogoUrl: gon.form_options.organization_thumbnail,
      companyName: gon.form_options.company_name,
      consultantOptions: [
        { text: 'Joshua Woo', value: 0 },
        { text: 'Kane Gan', value: 1 },
        { text: 'Leonard Wong', value: 2 },
        { text: 'Bowie Chin', value: 3 },
        { text: 'Sibyl Lim', value: 4 },
        { text: 'Stephanie T', value: 5 },
      ],
      nsOptions: [
        { text: 'Part-time', value: 0 },
        { text: 'Full-time', value: 1 },
        { text: 'Deferred', value: 2 },
        { text: 'Exempted', value: 3 },
      ],
    };
  },
  mounted() {
    $('header').hide();

    let image = $('#job_applications_container img.company-logo');

    setTimeout(function() {
      if (image.height() === image.width()) {
        // if image is square
        image.css('width', '150px');
      } else {
        image.css('width', '300px');
        image.css('margin-bottom', '20px');
      }
    }, 500);
  },
  watch: {
    'draft_employee.organization_id'() {
      this.getDepartments();
    },
    'draft_employee.department_id'() {
      this.getPositions();
    },
    address_line_1() {
      this.draft_employee.address_line_1 = this.address_line_1;
    },
    address_line_2() {
      this.draft_employee.address_line_2 = this.address_line_2;
    },
    address_line_3() {
      this.draft_employee.address_line_3 = this.address_line_3;
    },
    'draft_employee.postal_code'() {
      this.findAddressFromPostalCode();
    },
    'draft_employee.address_country_code'() {
      if (this.draft_employee.address_country_code == 'HK') {
        this.draft_employee.address_country_code = '';
      }
    },
  },
  methods: {
    submitForm() {},
    getDepartments() {
      // update department filter
      $.ajax({
        url: `/employees/departments.json`,
        method: 'get',
        data: { organization_id: this.draft_employee.organization_id },
        dataType: 'json',
        contentType: 'application/json',
      })
        .done((data) => {
          let temp_array = [];
          for (let i of data) {
            temp_array.push({ text: i[1], value: i[0] });
          }
          this.departmentOptions = temp_array;
        })
        .fail(({ responseJSON }) => {});
    },
    getPositions() {
      // update position filter
      $.ajax({
        url: `/employees/positions.json`,
        method: 'get',
        data: { department_id: this.draft_employee.department_id },
        dataType: 'json',
        contentType: 'application/json',
      })
        .done((data) => {
          let temp_array = [];
          for (let i of data) {
            temp_array.push({ text: i[1], value: i[0] });
          }
          this.positionOptions = temp_array;
        })
        .fail(({ responseJSON }) => {});
    },
    onAreaCodesUpdate: debounce(function() {
      this.findAddressFromPostalCode();
    }, 150),
    onAddressUpdate: debounce(function(address) {
      this.geocodeAddress(address);
    }, 150),
    findAddressFromPostalCode() {
      if (this.draft_employee.postal_code.length < 6) return;
      $.get(
        `/addresses/geocode?query=${this.draft_employee.postal_code}&country=${this.draft_employee
          .address_country_code || 'SG'}&type=1`
      ).then((data) => {
        this.address_line_1 = data.line_1;
        this.address_line_2 = data.line_2;
        this.address_line_3 = data.line_3;
      });
    },
    geocodeAddress(addressOrZipcode) {
      const region = this.draft_employee.address_country_code || 'SG';
      this.geocoder.geocode({ address: addressOrZipcode, region: region }, (results, status) => {
        if (status !== 'OK') return;
        const location = results[0].geometry.location;
        this.$emit('input', { ...this.draft_employee, latitude: location.lat(), longitude: location.lng() });
      });
    },
    isColorLight(color) {
      if (color) {
        const hex = color.replace('#', '');
        const c_r = parseInt(hex.substr(0, 2), 16);
        const c_g = parseInt(hex.substr(2, 2), 16);
        const c_b = parseInt(hex.substr(4, 2), 16);
        const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000;
        return brightness > 155;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
#job_application_form {
  div#job_applications_header {
    padding: 19px 0;
    background: #0d2e42;

    h1 {
      text-align: center;
    }

    p {
      color: white;
      margin-bottom: 0px;
      text-align: center;
    }

    img {
      width: 50px;
    }
  }

  div#job_applications_container {
    .row.title {
      margin: 45px auto;
    }

    img.company-logo {
      width: 200px;
    }

    #description {
      padding-left: 75px;
    }

    .card-panel.job_applications_card {
      h4 {
        text-align: left;
        margin-bottom: 25px;
      }
    }
  }
}

@media (max-width: 576px) {
  #job_application_form {
    #job_applications_container {
      #thumb {
        margin-bottom: 50px;
      }

      div.col.s12.m4.l4 {
        margin-top: 20px;
      }
    }
  }
}

@media (min-width: 767px) and (max-width: 1024px) {
  #job_application_form {
    #job_applications_container {
      #description {
        padding-left: 45px;
      }
    }
  }
}

::v-deep .flatpickr-input[type='text'] {
  width: 29vw;
  min-width: 200px;
  max-width: 420px;
}
</style>
