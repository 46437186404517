<template>
    <div class="file-field-wrapper file" :class="requiredClass">
        <div class="file-field input-field">
            <div class="btn" style='height: 40px;'>
                <span>{{ buttonLabel }}</span>
                <input :name="inputText" type="file" class="file" :class="requiredClass" @change="addFile" :disabled="disabled" ref="input">
                <slot name="button"></slot>
            </div>
            <div class="file-path-wrapper" >
                <input type="text" class="file-path validate" :value="fileName" :placeholder="placeholder">
                <slot name="text"></slot>
            </div>
        </div>
        <a class="remove" @click="removeFile" v-show="showRemoveButton"><i class='table-icon delete'></i></a>
    </div>
</template>

<script>

  function nullableString(val) {
    return val === null || typeof val === 'string' || val === undefined
  }

  export default {
    props: {
      // The model or file object. Should contain 'attribute', 'attribute_file_name', and 'attribute_delete' keys
      value: {
        validator: nullableString,
      },
      // The file name
      fileName: {
        validator: nullableString,
        required: true
      },
      // Whether the input is disabled or not
      disabled: {
        type: Boolean
      },
      // Whether the file should be deleted - '1' or '0'
      fileDelete: {
        type: String,
        default: '0'
      },
      // The placeholder for this input
      placeholder: {
        type: String
      },
      // The upload button label
      buttonLabel: {
        type: String,
        default: 'Upload File'
      },
      // Whether the file is a required field or not
      required: {
        type: Boolean,
        default: false
      },
      // Whether the remove file button is always shown
      alwaysAllowRemove: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        requiredClass: this.required ? 'required' : 'optional',
        inputText: 'data-file'
      }
    },
    computed: {
      showRemoveButton() {
        return this.alwaysAllowRemove || this.fileName;
      }
    },
    methods: {
      addFile(e) {
        let reader  = new FileReader();
        const file = e.target.files[0];
        reader.addEventListener('load', () => {
          this.$emit('input', reader.result);
          this.$emit('update:fileName', file.name);
          this.$emit('update:fileDelete', '0');
          this.$refs.input.value = ''; // hack to ensure onchange event is always fired even for same file
        });
        if (file) { reader.readAsDataURL(file); }
      },
      removeFile() {
        swalConfirm('Really remove?').then((result) => {
          if (result.value) {
            this.$emit('input', null);
            this.$emit('update:fileName', null);
            this.$emit('update:fileDelete', '1');
            this.$emit('remove');
            this.$refs.input.value = ''; // hack to ensure onchange event is always fired even for same file
          }
        });
      }
    }
  }
</script>
