<template>
    <span v-if="isHorizontal" class="radio">
        <input :id="_uid" :type="type" class="with-gap" :disabled='disabled' :checked="value" @change="onChange($event.target.checked)">
        <label :for="_uid" :class="labelClass" :style="labelStyle">{{ label }}</label>
    </span>
    <p v-else class="boolean" :class="{ 'has-error': errors }">
        <input :id="_uid" :type="type" class="boolean with-gap" :disabled='disabled' :class="inputClass" :checked="value" @change="onChange($event.target.checked)">
        <label :for="_uid" class="boolean" :class="labelClass">
          <slot name="label">
            {{ label }}
          </slot>
        </label>
        <span v-if="hint" v-html="hint" class="help-block" />
        <span v-if="errors" class="error-block">{{ errors[0] }}</span>
    </p>
</template>

<script>
  export default {
    props: {
      value: {
        type: Boolean,
      },
      errors: {
        type: Array,
      },
      type: {
        type: String,
        default: 'checkbox'
      },
      isHorizontal: {
        type: Boolean,
        default: false,
      },
      label: {
        type: String,
        required: true,
      },
      labelClass: {
        type: String,
      },
      labelStyle: {
        type: String,
      },
      inputClass: {
        type: String,
      },
      disabled: {
        type: Boolean,
      },
      hint: {
        type: String,
      },
    },
    methods: {
      onChange(checked) {
        this.$emit('input', checked);
        this.$emit('change', checked);
      }
    }
  }
</script>
