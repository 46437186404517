<template>
  <div>
    <form @submit.prevent="submitAfterUpdates" class="simple_form" novalidate="novalidate">
      <slot name="header"></slot>
        <blockquote v-if="hasErrors" class="error-notification">
            Please correct the errors highlighted below:
        </blockquote>
        <div class="form-inputs" :class="{ 'inputs-has-errors': hasErrors }">
            <slot></slot>
        </div>
        <slot name="footer">
            <div class="form-actions prevent-buttons-from-overlapping">
                <a href="#!" @click.stop.prevent="goBack" class="btn btn-default">{{cancelText}}</a>
                <button type="submit"
                        name="commit"
                        class="btn btn-primary"
                        v-show="showSubmit"
                        :disabled="submitBtnDisabled">
                    {{ isSubmitting ? submittingText : submitText }}
                </button>
            </div>
        </slot>
    </form>
    <modal :width="500" :height="250" :adaptive="true" name="success-popup" id="form-success-popup-style">
      <div class="popup-container center">
        <h5 class="popup-msg">{{successMsg}}</h5>
        <button
          type="button"
          v-on:click="goRootPath()"
          class="btn btn-primary">BACK TO HOME</button>
      </div>
    </modal>
  </div>
</template>

<script>
  import { errorToast, modelHasErrors, updateModelWithErrors } from '../util/errors'
  import { postData } from '../forms/utils/AjaxRequests';

  export default {
    props: {
      // The model to submit for this form
      value: {},
      // The endpoint for this form (a.k.a. HTML form `action`); ID parameter is automatically appended in Rails-style
      action: {
        type: String,
        required: true
      },
      // The name of the model which will be used as the top level JSON key in the request
      modelName: {
        type: String,
        required: true
      },
      // Will not try to append the model ID to the `action` (endpoint) if this is `true`
      isSingular: {
        type: Boolean,
        default: false
      },
      // The HTTP method to use. This will be smartly guessed if not provided
      method: {
        type: String
      },
      // Any additional params to append to the form besides the model
      params: {
        type: Object,
        default: () => {}
      },
      // Back button text
      back: {
        type: String,
        default: null
      },
      // Submit button text
      submitText:{
        type: String,
        default: 'Submit'
      },
      // Cancel button text
      cancelText:{
        type: String,
        default: 'Cancel'
      },
      // Submit button text when form is submitting and button is disabled
      submittingText: {
        type: String,
        default: 'Submitting..'
      },
      // Function to execute synchronously before submitting
      beforeSubmit: {
        type: Function
      },
      // Show sweetalert warning before submitting
      beforeSubmitWarn: {
        type: String,
        default: null
      },
      // Show sweetalert confirmation before submitting
      beforeSubmitConfirm: {
        type: String,
        default: null
      },
      // Allows disabling the submit button temporarily
      // Note that this does not override the internal disabling of submit button
      // when the form is being submitted.
      disableSubmit: {
        type: Boolean,
        default: false
      },
      // To verify some data before form submission, if it is true, submit the form. Else, it won't submit.
      verifyOnSubmit: {
        type: Boolean,
        default: true
      },
      showSubmit: {
        type: Boolean,
        default: true
      },
      swalCancelText: {
        type: String,
        default: null
      },
    },
    data() {
      return {
        isSubmitting: false,
        successMsg: 'The form has been submitted successfully!',
        settings: {
          enable_2fa_employee: ''
        }
      }
    },
    computed: {
      hasErrors() {
        return this.value && modelHasErrors(this.value);
      },
      submitBtnDisabled() {
        return this.disableSubmit || this.isSubmitting;
      }
    },
    methods: {
      goRootPath(){
        document.location.href = "/";
      },
      goBack() {
        if (this.swalCancelText) {
          swalWarn(this.swalCancelText).then(result => {
            if (result.value) {
              this.linkBack();
            }
          })
        } else {
          this.linkBack();
        }
      },
      linkBack() {
        if (this.back) {
          Turbolinks.visit(this.back);
        } else {
          window.history.back();
        }
      },
      submitAfterUpdates() {
        this.isSubmitting = true;
        this.$nextTick(() => this.submitForm())
      },
      showModal(res) {
        if (res.form_submitted){
          window.scrollTo(0,0);
          this.$modal.show('success-popup');
        }
      },
      submitForm() {
        if (this.disableSubmit) {
          setTimeout(() => this.submitForm(), 3000);
          return
        }

        if(!this.verifyOnSubmit) {
          return
        }

        if (this.beforeSubmit) this.beforeSubmit();

        if (this.beforeSubmitWarn) {
          this.$emit('afterSubmit', null) //set emit before swalWarn to prevent message from appearing twice because beforeSubmitWarn value is asynchronously added to swalWarn
          swalWarn(this.beforeSubmitWarn).then(result => {
            if (result.value) {
              this.submitForm();
            }
          });
          return;
        }

        if (this.beforeSubmitConfirm) {
          this.$emit('afterSubmit', null) //set emit before swalWarn to prevent message from appearing twice because beforeSubmitConfirm value is asynchronously added to swalWarn
          swalConfirm(this.beforeSubmitConfirm).then(result => {
            if (result.value) {
              this.submitForm();
            }
          });
          return;
        }

        let data = Object.assign({}, { [this.modelName]: this.value, format: 'js' }, this.params);
        $.ajax({
          url: `${this.action}/${this.isSingular ? '' : (this.value.id || '')}`,
          method: this.method || (this.value.id || this.isSingular ? 'patch' : 'post'),
          data: JSON.stringify(data),
          contentType: 'application/json',
          success: this.showModal
        }).fail(xhr => {
          window.scrollTo(0, 0);
          if (xhr.responseJSON) {
            const errorModel = xhr.responseJSON[this.modelName];
            const errorText = xhr.responseJSON.error || xhr.responseJSON.errors;
            if (errorModel) {
              this.$emit('input', updateModelWithErrors(this.value, errorModel));
            } else if (errorText) {
              errorToast(errorText);
            } else {
              // FIXME: this doesn't seem safe or correct - which controller responses without a key??
              this.$emit('input', xhr.responseJSON);
            }
          } else {
            errorToast();
          }
          this.isSubmitting = false;
        });
      }
    }
  }
</script>

<style lang='scss' scoped>
.popup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 20px;
}
.popup-msg {
  padding: 30px;
}
#form-success-popup-style {
  backdrop-filter: blur(4px);
}
#form-success-popup-style .popup-msg {
  font-size: 1.4rem;
  text-transform: none;
}
button.btn, a.btn {
  font-weight: 400;
  font-family: 'Changa' !important;
  text-transform: uppercase;
  box-shadow: none;
  cursor: pointer;
  text-transform: capitalize;
  border-radius: 8px;
  outline: none;
  background: white;
  color: #101828;
  border: 1px solid #D0D5DD;

  &:hover, &:focus {
    background: #F9FAFB;
    border: 1px solid #98A2B3;
    box-shadow: none;
  }

  &.btn-primary {
    color: white;
    background: #004eeb !important;
    border: 1px solid #004eeb;

    &[disabled=disabled], &.btn:disabled  {
      color: white !important;
      background: #B2CCFF !important
    }

    &:hover {
      background: #004EEB !important;
      box-shadow: none;
    }
    &:focus {
      outline: #004EEB;
    }
    &:active {
      background: #004eeb;
      border: 1px solid #004eeb;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 3px #D1E0FF;
    }
  }

  &.btn-delete {
    color: white;
    background: #f04438;
    border:  1px solid #f04438;

    &:hover {
      background: #B42318;
    }
  }
}

</style>
