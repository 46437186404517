const numeral = require("numeral");
const currencyFormatter = require("currency-formatter");

function formatDecimal(s) {
  return numeral(s).format("0.00");
}
function formatNumber(s) {
  return numeral(s).format("0");
}

function formatCurrency(s) {
  return numeral(s).format("$0,0.00");
}

function formatRespectiveCurrency(value, currencyCode) {
  // return currencyFormatter.format(value, { code: currencyCode });
  return new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: currencyCode,
  }).format(value);
}

function formatShowMore(s) {
  return s > 0 ? numeral(s).format("0[.]0a+") : "0";
}

module.exports = {
  formatDecimal,
  formatNumber,
  formatCurrency,
  formatRespectiveCurrency,
  formatShowMore,
};
