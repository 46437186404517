<template>
    <form @submit.prevent="submitForm" class="simple_form" novalidate="novalidate">
        <modal v-show="showModal" @close="!isSubmitting && $emit('close')">
            <template v-slot:header>
                <slot name="header">
                    <h4 class="modal-form-title" ref="header">{{ titleText }}</h4>
                </slot>
            </template>
            <template v-slot:body>
                <blockquote v-if="hasErrors" class="error-notification">
                    Please correct the errors highlighted below:
                </blockquote>
                <blockquote v-if="errors" class="error-notification">
                    Please correct the errors listed below:
                </blockquote>
                <ul id="errors-list" v-if="errors">
                  <li class="error-notification" :key="error" v-for="error in errors">
                    - {{ error }}
                  </li>
                </ul>
                <div class="form-inputs">
                    <slot></slot>
                </div>
            </template>
            <template v-slot:footer>
                <button type="submit"
                        name="commit"
                        class="btn btn-primary right"
                        v-show="showSubmit"
                        :disabled="disableSubmit || isSubmitting">
                    {{ isSubmitting ? submittingText : submitText }}
                </button>
                <button class="btn btn-default right" style='margin-right: 5px;' @click="$emit('close')">
                  Cancel
                </button>
            </template>
        </modal>
    </form>
</template>

<script>
  import Modal from './modal.vue'
  import { errorToast, modelHasErrors, updateModelWithErrors } from '../util/errors'

  export default {
    components: { Modal },
    props: {
      // The model to submit for this form
      value: {},
      // The endpoint for this form (a.k.a. HTML form `action`); ID parameter is automatically appended in Rails-style
      action: {
        type: String,
        required: true
      },
      // The name of the model which will be used as the top level JSON key in the request
      modelName: {
        type: String,
        required: true
      },
      // Will not try to append the model ID to the `action` (endpoint) if this is `true`
      isSingular: {
        type: Boolean,
        default: false
      },
      // Any additional params to append to the form besides the model
      params: {
        type: Object,
        default: () => {}
      },
      // Submit button text
      submitText:{
        type: String,
        default: 'Submit'
      },
      // Submit button text when form is submitting and button is disabled
      submittingText: {
        type: String,
        default: 'Submitting..'
      },
      // Close button text
      closeText: {
        type: String,
        default: 'Cancel'
      },
      // Function to execute synchronously before submitting
      beforeSubmit: {
        type: Function
      },
      // Allows disabling the submit button temporarily
      // Note that this does not override the internal disabling of submit button
      // when the form is being submitted.
      disableSubmit: {
        type: Boolean,
        default: false
      },
      // Whether the modal is currently shown or hidden
      showModal: {
        type: Boolean,
        required: true
      },
      // Modal title
      titleText: {
        type: String,
        default: 'Edit'
      },
      showSubmit: {
        type: Boolean,
        default: true
      },
      // Whether cancel button is currently shown or hidden
      showCancel: {
        type: Boolean,
        default: true
      },
      // Whether from reassignment_row.vue
      fromReassignment: {
        type: Boolean,
        default: false
      },
    },
    computed: {
      hasErrors() {
        return this.value && modelHasErrors(this.value)
      }
    },
    data() {
      return {
        isSubmitting: false,
        errors: ''
      }
    },
    methods: {
      submitForm() {
        this.isSubmitting = true;

        if (this.beforeSubmit) this.beforeSubmit();

        let data = Object.assign({}, { [this.modelName]: this.value }, this.params);

        $.ajax({
          url: `/${this.action}/${this.isSingular ? '' : (this.value.id || '')}`,
          method: this.value.id || this.isSingular ? 'patch' : 'post',
          data: JSON.stringify(data),
          dataType: 'json',
          contentType: 'application/json'
        }).done((...args) => {
          this.$emit('success', ...args)
          if (this.fromReassignment) {
            this.errors = '';
          }
        }).fail(xhr => {
          this.$refs.header.scrollIntoView();
          if (xhr.responseJSON) {
            const errorModel = xhr.responseJSON[this.modelName];
            if (errorModel) {
              this.$emit('input', updateModelWithErrors(this.value, errorModel));
            } else {
              if (this.fromReassignment) {
                let errString = xhr.responseText;
                let errArray = [];
                errString = xhr.responseText.substring(1, errString.length -1);
                errString = errString.split(",");
                errString.map(err => {
                  let error = err.substring(1, err.length-1)
                  errArray.push(error)
                })
                this.errors = errArray;
              } else {
                errorToast(xhr.responseText);
              }
            }
          } else {
            errorToast();
          }
        }).always(() => {
            this.isSubmitting = false;
        });
      },
    }
  }
</script>

<style scoped>
    .modal-form-title {
        margin-top: 0;
    }
    .form-inputs {
        margin-top: 15px;
    }
    .modal-btn {
        border: none;
        border-radius: 2px;
        height: 48px;
        line-height: 48px;
        padding: 0 2rem;
        text-transform: uppercase;
    }

    .modal-submit-btn {
        color: #9bcc8b;
        float: right;
    }

    .modal-cancel-btn {
        color: #aaaaaa;
    }
</style>
