<template>
  <div class="container" :style="steps === 1 ? 'padding-bottom: 0.5rem' : 'padding-bottom: 4.5rem'">
    <p style="font-size: 1.5rem; font-weight: 600;">{{visitorTemplate.name}}</p>
    <materialize-form  class="form-font-size" v-model="visitor" :action="formEndpoint" modelName="visitor"  ref="form">
      <div class="company-name-header-container">
        <p>{{visitor.company_name}}</p>
        <div>
          <small v-for="outletName in outletNamesArr" :key="outletName">
            {{outletName}}
          </small>
        </div>
      </div>


      <div v-if="steps == 1" class="card-panel">
          <materialize-input
            label="Mobile Number"
            v-model="visitor.phone_number"
            :errors="visitor.errors.phone_number"
            :required="true"
            type="number"
            class="col s12 m6"
          />
      </div>

          <!-- BASIC INFORMATION -->
      <div v-if="steps == 2" class="card-panel">
        <h5>Basic Information</h5>
        <br>
        <div class="form-inputs row">
          <materialize-input
            label="Name (as per NRIC)"
            v-model="visitor.name"
            :errors="visitor.errors.name"
            :required="true"
            :disabled="this.visitor.id != null"
            class="col s12 m6"
          />
          <materialize-input
            label="Mobile Number"
            v-model="visitor.phone_number"
            :errors="visitor.errors.phone_number"
            :required="true"
            :disabled="true"
            type="number"
            class="col s12 m6"
          />
          <materialize-input
            v-if="visitor.visitor_form_records[visitor.visitor_form_records.length - 1].visitor_template.identity_number_enabled"
            label="Primary Identity Number"
            v-model="visitor.identity_number"
            :disabled="this.visitor.id != null"
            :errors="visitor.errors.identity_number"
            :required="visitor.visitor_form_records[visitor.visitor_form_records.length - 1].visitor_template.identity_number_required"
            class="col s12 m6"
          />
          <br>
          <span v-if="showPhotoField">
            <div class="col s12 m6" style="margin-bottom: 1rem;" v-if="this.visitor.id == null">
              <label for="image-file">Upload your selfie image</label>
              <input id="image-file" v-on:change="createPhoto($event)" type="file" accept="image/*" class="photo-upload">
              <span v-if="visitor.errors.photo" class="error-block">{{ visitor.errors.photo[0] }}</span>
            </div>

            <div v-else class="col m12 l4">
              <img
                id="photo_id"
                v-bind:src="visitor.photo.url"
                alt="photo"
                width="100%"
              />
            </div>
          </span>

        </div>
        <div class="card-panel visitor-form-fields" style="padding: 1rem;" v-if="visitor.visitor_form_records[visitor.visitor_form_records.length - 1].visitor_template.questions.length > 0">
          <div>{{getCurrentDateTime()}}</div>
          <div class="divider"></div>
          <div v-for="(question, question_index) in visitor.visitor_form_records[visitor.visitor_form_records.length - 1].visitor_template.questions" :key="question_index">
            <form-field
              style="margin: 1.5rem"
              v-bind:field='question'
              v-bind:answer='visitor.visitor_form_records[visitor.visitor_form_records.length - 1].answers.find(answer => answer.question_id === question.id)'
              v-bind:canEdit='true'
            />
          </div>
        </div>
        <div class="divider"></div>
        <div class="description">
          <strong>Disclaimer:</strong>
          <ul>
            <li>By providing personal data through this declaration form, you authorise {{visitor.company_name}} to collect, use, process and disclose personal data provided for the purpose of verifying your identity, screening your health status and maintaining records of visitors to {{visitor.company_name}} premises for contact tracing purposes, to ensure our safety and security.  The information collated will be securely discarded after 1 month.</li>
          </ul>
        </div>

      </div>
      <template v-slot:footer class="form-actions" style="padding-bottom: 4rem">
        <a @click.prevent="goBack" class="btn btn-default ">
          {{ backText }}
        </a>
        <button v-if="steps == 1" @click.prevent="goNext" class="btn btn-default">
          Next
        </button>
        <button v-if="steps == 2" class="btn btn-primary" type="submit" @click.prevent="goNext">
          Submit
        </button>
      </template>
    </materialize-form>
  </div>
</template>


<script>
    import MaterializeForm from '../../components/materialize_form';
    import MaterializeInput from '../../components/materialize_input';
    import Selectize from '../../components/selectize.vue';
    import FormField from '../../components/form_field.vue';
    import {successToast} from '../../util/errors';

    export default {
      components: {MaterializeForm, MaterializeInput, Selectize, FormField },
      data() {
        return {
          visitor: gon.visitor,
          steps: 1,
          isValidating: false,
          visitorTemplate: gon.visitor.visitor_form_records[gon.visitor.visitor_form_records.length - 1].visitor_template
        }
      },
      computed: {
        outletNamesArr() {
          const outletNames = [];
          for(const device of this.visitor.visitor_form_records[this.visitor.visitor_form_records.length - 1].visitor_template.entry_devices) {
            outletNames.push(device.outlet_name)
          }
          return [...new Set(outletNames)];
        },
        formEndpoint() {
          return this.steps == 1 ? '/visitor_form_records/import' : '/visitor_form_records';
        },
        backText() {
          return this.steps === 1 ? 'Cancel' : 'Back';
        },
      },
      methods: {
        getCurrentDateTime() {
          const currentDateTime = new Date();
          return moment(currentDateTime).format('dddd, MMMM Do YYYY, h:mm a');
        },
        showPhotoField() {
          if (
            (this.visitor.visitor_form_records[visitor.visitor_form_records.length - 1].visitor_template.entry_device_ids.length > 0)
            ||
            (this.visitor.visitor_form_records[visitor.visitor_form_records.length - 1].visitor_template.facial_device_ids.length > 0)
          ) {
            return true;
          } else {
            return false;
          }
        },
        createPhoto(event) {
          console.log("create")
          var file = event.target.files[0];
          let reader  = new FileReader();
          reader.addEventListener('load', async() => {
            try{
              this.visitor.photo = reader.result;
            } catch(e) {
              swalWarn('Photo upload failed. Please try again.');
            }
          });
          if (file) {
            reader.readAsDataURL(file);
          }
        },
        goBack() {
          if(this.steps === 1) {
            window.history.back()
          } else{
            this.steps -= 1;
          }
        },
        goNext() {
          if (this.steps == 1) {
            if (this.visitor.phone_number == null || this.visitor.phone_number.length == 0) {
              return this.visitor.errors = {phone_number: ['cannot be blank']}
            } else {
              this.visitor.errors = {}
              $.ajax({
                url: '/visitor_form_records/import.json',
                method: 'GET',
                data: {
                  company_id: this.visitor.company_id,
                  phone_number: this.visitor.phone_number
                }
              }).done(( res ) => {
                if (res != null) {
                  this.visitor.id = res.visitor.id
                  this.visitor.name = res.visitor.name
                  this.visitor.identity_number = res.visitor.identity_number
                  this.visitor.photo.url = res.visitor.photo.url
                }
                this.steps += 1
              })
            }
          } else {
            this.$refs.form.submitAfterUpdates()
            successToast("Visitor form successfully submitted.")
          }
        }
      }
    }
</script>

<style lang="scss">
  @import '~colors';

  .company-name-header-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    p {
      font-size: 1.5rem;
    }

    small {
      font-size: 0.8rem;
      background-color: $payboy-grey;
      padding: 0 0.5rem;
      margin-right: 0.5rem;
      border-radius: 5px;
      border-bottom: 1px solid $payboy-dark-blue;
    }
  }

  .visitor-form-fields {
    // to fix the issue where label's positions are absolute which cause radio buttons labels to overlay
    // with other items on top. So, scoped is removed and added below attribute to ensure the attribute flows to its children
    .input-field {
      .active {
        position: initial;
      }
    }

    .radio_buttons {
      display: flex;
      flex-direction: column;
    }
  }




</style>
