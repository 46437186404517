<template>
  <input
    ref="input"
    type="number"
    :value="value"
    :disabled="disabled"
    :required="required"
    @change="onChange($event.target.value)"
    @input="onInput($event.target.value)"
    @keydown.enter.prevent
  />
</template>
<script>
import { formatNumber } from '../util/format';

export default {
  props: ['value', 'required', 'disabled'],
  methods: {
    formatValue(value, strictMode) {
      // Only format if not potentially typing a currency, or in strict mode
      if (strictMode === undefined || strictMode === null) {
        strictMode = true;
      }
      return String(value).match(/^[0-9]*[.]?[0-9]?$/g) && !strictMode ? value : formatNumber(value);
    },
    onChange(value) {
      let formattedValue = this.formatValue(value, true);
      if (formattedValue !== value) {
        this.$refs.input.value = formattedValue;
      }
      this.$emit('input', Number(formattedValue));
      this.$emit('change', Number(formattedValue));
    },
    onInput(value) {
      let formattedValue = this.formatValue(value, false);
      if (formattedValue !== value) {
        this.$refs.input.value = formattedValue;
      }
      this.$emit('input', Number(formattedValue));
    },
  },
  mounted() {
    this.$refs.input.value = this.formatValue(this.value, true);
  },
};
</script>
