<template>
  <div>
    <div class="row">

      <!-- HEADER -->
      <h3 v-show="field.field_type == 'header'"><strong>{{field.label || "Untitled"}}</strong></h3>

      <!-- SUBHEADER -->
      <h5 v-show="field.field_type == 'subheader'">{{field.label || "Untitled"}}</h5>

      <!-- DESCRIPTION -->
      <p v-show="field.field_type == 'description'"><strong>{{field.label || "Untitled"}}</strong></p>

      <!-- NUMBER BOX -->
      <div v-show="field.field_type == 'number_box'">
        <materialize-input
          v-model="answer.input"
          v-bind:label="field.label || 'Untitled'"
          type="number"
          placeholder="Enter number here"
          :required="field.required"
          :disabled="!canEdit"
          :errors="answer.errors.input"
          class="col l12"
        />
      </div>

      <!-- TEXT BOX -->
      <div v-show="field.field_type == 'text_box'">
        <materialize-input
          v-model="answer.input"
          v-bind:label="field.label || 'Untitled'"
          type="text"
          placeholder="Enter text here"
          :required="field.required"
          :disabled="!canEdit"
          :errors="answer.errors.input"
          class="col l12"
        />
      </div>

      <!-- TEXT AREA -->
      <div v-show="field.field_type == 'text_area'">
        <materialize-input
          v-model="answer.input"
          v-bind:label="field.label || 'Untitled'"
          type="textarea"
          placeholder="Enter text here"
          :required="field.required"
          :disabled="!canEdit"
          :errors="answer.errors.input"
          class="col l12"
        />
      </div>

      <!-- DATE -->
      <div v-show="field.field_type == 'date'">
        <materialize-input
          v-model="answer.input"
          v-bind:label="field.label || 'Untitled'"
          type="date"
          placeholder="Select a date"
          :required="field.required"
          :disabled="!canEdit"
          :errors="answer.errors.input"
          class="col l12"
        />
      </div>

      <!-- RADIO -->
      <div v-show="field.field_type == 'radio'">
        <materialize-input v-bind:label="field.label || 'Untitled'" :disabled="!canEdit" class="col l12" :errors="answer.errors.input">
          <materialize-checkboxes v-model="answer.input" :options="field.options" :isHorizontal="true" :disabled="!canEdit" ></materialize-checkboxes>
        </materialize-input>

        <materialize-input
          v-show="showSpecifyField && field.enable_specify_input"
          v-model="answer.others_input"
          label="Please specify"
          type="text"
          placeholder="Enter text here"
          :required="field.specify_input_required"
          :disabled="!canEdit"
          :errors="answer.errors.others_input"
          class="col l12"
        />
      </div>

      <!-- CHECKBOX -->
      <div v-show="field.field_type == 'checkbox'">
        <materialize-input
          v-model="answer.input"
          v-bind:label="field.label || 'Untitled'"
          type="checkbox"
          :required="field.required"
          :disabled="!canEdit"
          :errors="answer.errors.input"
          class="col l12"
        />
      </div>

      <!-- SELECT -->
      <div v-show="field.field_type == 'select'">
        <materialize-input
          v-model="answer.input"
          v-bind:label="field.label || 'Untitled'"
          type="select"
          :options="field.options"
          :required="field.required"
          :disabled="!canEdit"
          placeholder="Select an option"
          :errors="answer.errors.input"
          class="col l12"
        />
        <div v-if="!canEdit" v-for="option in field.options">
          <p>  ⎿  {{option.text || 'Untitled'}}</p>
        </div>
        <br>
        <materialize-input
          v-show="showSpecifyField && field.enable_specify_input"
          v-model="answer.others_input"
          label="Please specify"
          type="text"
          placeholder="Enter text here"
          :required="field.specify_input_required"
          :disabled="!canEdit"
          :errors="answer.errors.others_input"
          class="col l12"
        />
      </div>

      <!-- FILE UPLOAD -->
      <div v-show="field.field_type == 'file'" class="col l12" >
        <h5>{{field.label || 'Untitled'}}</h5>
        <materialize-input
          v-if="!canEdit"
          label="Select File"
          placeholder=".pdf, .docx, .xlsx, .pptx, .txt, .jpg, .png"
          type="file"
          :disabled="!canEdit"
        />
        <materialize-input
          v-else-if="field.field_type == 'file' && canEdit"
          v-model="answer.document.file"
          :fileName.sync="answer.document.file_file_name"
          :fileDelete.sync="answer.document.file_delete"
          label="Select File"
          placeholder=".pdf, .docx, .xlsx, .pptx, .txt, .jpg, .png"
          type="file"
          :errors="answer.document.errors.file"
          :disabled="!canEdit"
        />
        <p class="col s12 instruction" v-if="field.required">
          <small><abbr>*</abbr> Required fields</small>
        </p>
      </div>

      <!-- DIVIDER -->
      <div v-show="field.field_type == 'divider'"><hr><br></div>

    </div>



    <p v-if="invalidLabel" style="color: red">Invalid Label (Click here to open settings)</p>
    <p v-if="invalidOptions" style="color: red">Invalid Option/s (Click here to open settings)</p>

    <div v-if="field.index == selectedIndex && field.field_type != 'divider'" class="setting">
      <div class="row">
        <a @click="closeSetting()" class="right">
          <i class="left">Close Setting [ X ]</i> 
        </a>
        <h5><u>{{fieldTypeName}} Settings</u></h5>
        <br>
        <materialize-input
          label="Type"
          v-model="field.field_type"
          type="select"
          :options="fieldTypeList"
          :errors="field.errors.field_type"
        />
        <br>
        <!-- HEADER SETTINGS -->
        <div v-show="field.field_type == 'header'">
          <materialize-input
            v-model="field.label"
            label="Key in the header"
            type="text"
            placeholder="Enter your text here"
            :errors="field.errors.label"
            :required="true"
          />
        </div>

        <!-- SUBHEADER SETTINGS -->
        <div v-show="field.field_type == 'subheader'">
          <materialize-input
            v-model="field.label"
            label="Key in the subheader"
            type="text"
            placeholder="Enter your text here"
            :required="true"
            :errors="field.errors.label"
          />
        </div>

        <!-- DESCRIPTION SETTINGS -->
        <div v-show="field.field_type == 'description'">
          <materialize-input
            v-model="field.label"
            label="Key in the description"
            type="text"
            placeholder="Enter your text here"
            :required="true"
            :errors="field.errors.label"
          />
        </div>

        <!-- NUMBER BOX SETTING -->
        <div v-show="field.field_type == 'number_box'">
          <materialize-input
            v-model="field.label"
            label="Key in the label for the number box"
            type="text"
            placeholder="Enter your text here"
            :required="true"
            :errors="field.errors.label"
          />
          <materialize-input
            v-model="field.required"
            label="Required?"
            type="checkbox"
            :required="true"
          />
        </div>

        <!-- TEXT BOX SETTING -->
        <div v-show="field.field_type == 'text_box'">
          <materialize-input
            v-model="field.label"
            label="Key in the label for the text box"
            type="text"
            placeholder="Enter your text here"
            :required="true"
            :errors="field.errors.label"
          />
          <materialize-input
            v-model="field.required"
            label="Required?"
            type="checkbox"
            :required="true"
          />
        </div>

        <!-- TEXT AREA SETTING -->
        <div v-show="field.field_type == 'text_area'">
          <materialize-input
            v-model="field.label"
            label="Key in the label for the text area"
            type="text"
            placeholder="Enter your text here"
            :required="true"
            :errors="field.errors.label"
          />
          <materialize-input
            v-model="field.required"
            label="Required?"
            type="checkbox"
            :required="true"
          />
        </div>

        <!-- TEXT AREA SETTING -->
        <div v-show="field.field_type == 'date'">
          <materialize-input
            v-model="field.label"
            label="Key in the label for the date field"
            type="text"
            placeholder="Enter your text here"
            :required="true"
            :errors="field.errors.label"
          />
          <materialize-input
            v-model="field.required"
            label="Required?"
            type="checkbox"
            :required="true"
          />
        </div>

        <!-- RADIO SETTING -->
        <div v-show="field.field_type == 'radio'">
          <materialize-input
            v-model="field.label"
            label="Key in the label for the radio button"
            type="text"
            placeholder="Enter your text here"
            :required="true"
            :errors="field.errors.label"
          />
          <div class="col l8">
            <div v-if="option.text != 'Others'" v-for="(option, index) in field.options">
              <materialize-input
                v-model="field.options[index].text"
                v-bind:label="'Option ' + (index + 1)"
                type="text"
                placeholder="Enter your text here"
                :required="true"
                class="col l11"
                :errors="validOptionLabel(field.options[index].text)"
              />
              <div class="remove col l1" v-if="index > 1">
                <a @click="deleteOption(index)" class="btn btn-flat transparent materialize-red-text darken-2-text">X</a>
              </div>
              <br><br>
            </div>
            <br><br>
            <div class="col l12">
              <button class="small-btn payboy-orange" @click.prevent="addOption">
                Add new option
              </button>
            </div>

          </div>
          <div class="col l4">
            <materialize-input
              v-model="field.enable_others_option"
              label="Allow 'Others' option"
              type="checkbox"
              :required="true"
              class="col l12"
            />
            <materialize-input
              v-if="field.enable_others_option"
              v-model="field.enable_specify_input"
              label="Enable 'Please Specify' input"
              type="checkbox"
              :required="true"
              class="col l12"
            />
            <materialize-input
              v-if="field.enable_specify_input"
              v-model="field.specify_input_required"
              label="'Please Specify' input is required"
              type="checkbox"
              :required="true"
              class="col l12"
            />
          </div>
          <br>
        </div>

        <!-- CHECKBOX SETTING -->
        <div v-show="field.field_type == 'checkbox'">
          <materialize-input
            v-model="field.label"
            label="Key in the label for the checkbox"
            type="text"
            placeholder="Enter your text here"
            :required="true"
            :errors="field.errors.label"
          />
          <materialize-input
            v-model="field.required"
            label="Required?"
            type="checkbox"
            :required="true"
          />
        </div>

        <!-- SELECT SETTING -->
        <div v-show="field.field_type == 'select'">
          <materialize-input
            v-model="field.label"
            label="Key in the label for the select box"
            type="text"
            placeholder="Enter your text here"
            :required="true"
            :errors="field.errors.label"
          />
          <div class="col l8">
            <div v-if="option.text != 'Others'" v-for="(option, index) in field.options">
              <materialize-input
                v-model="field.options[index].text"
                v-bind:label="'Option ' + (index + 1)"
                type="text"
                placeholder="Enter your text here"
                :required="true"
                class="col l11"
                :errors="validOptionLabel(field.options[index].text)"
              />
              <div class="remove col l1" v-if="index > 1">
                <a @click="deleteOption(index)" class="btn btn-flat transparent materialize-red-text darken-2-text">X</a>
              </div>
              <br>
            </div>
            <br>
            <div class="col l12">
              <button class="small-btn payboy-orange" @click.prevent="addOption">
                Add new option
              </button>
            </div>
          </div>
          <div class="col l4">
            <materialize-input
              v-model="field.enable_others_option"
              label="Allow 'Others' option"
              type="checkbox"
              :required="true"
              class="col l12"
            />
            <materialize-input
              v-if="field.enable_others_option"
              v-model="field.enable_specify_input"
              label="Enable 'Please Specify' input"
              type="checkbox"
              :required="true"
              class="col l12"
            />
            <materialize-input
              v-if="field.enable_specify_input"
              v-model="field.specify_input_required"
              label="'Please Specify' input is required"
              type="checkbox"
              :required="true"
              class="col l12"
            />
          </div>
          <br><br>
          <materialize-input
            v-model="field.required"
            label="Required?"
            type="checkbox"
            :required="true"
            class="col l12"
          />
        </div>

        <!-- FILE SETTING -->
        <div v-show="field.field_type == 'file'">
          <materialize-input
            v-model="field.label"
            label="Key in the label for the file upload"
            type="text"
            placeholder="Enter your text here"
            :required="true"
            :errors="field.errors.label"
          />
          <materialize-input
            v-model="field.required"
            label="Required?"
            type="checkbox"
            :required="true"
          />
        </div>

      </div>

    </div>





  </div>
</template>

<script>
  import MaterializeInput from "../components/materialize_input";
  import MaterializeCheckboxes from '../components/materialize_checkboxes.vue';
  import Materialize_input from './materialize_input.vue';
  import _ from 'lodash';

  export default {
    components: { MaterializeInput, MaterializeCheckboxes, Materialize_input },
    props: {
      field: {
        type: Object,
        default: () => ({})
      },
      answer: {
        type: Object,
        default: () => ({errors: []})
      },
      canEdit: {
        type: Boolean,
        default: false
      },
      selectedIndex: {
        type: Number
      }
    },
    data() {
      return {
        fieldTypeList: gon.form_options.field_type_list,
        showSpecifyField: false
      }
    },
    mounted() {
      this.setOptionsData()
    },
    watch: {
      'field.field_type'() {
        this.setOptionsData()
      },
      'field.enable_others_option'() {
        if (this.field.enable_others_option == true) {
          this.field.options.push({value: this.field.options.length.toString(), text: "Others"})
        } else {
          this.field.options.splice(this.field.options.findIndex(({text}) => text == "Others", 1))
          this.field.enable_specify_input = false
          this.field.specify_input_required = false
        }
      },
      'answer.input'() {
        if (this.field.enable_specify_input && (!this.canEdit || this.answer.input == this.field.options.length - 1)) {
          this.showSpecifyField = true
        } else {
          this.showSpecifyField = false
        }
      },
    },
    methods: {
      setOptionsData() {
        if (_.isEmpty(this.field.options)){
          if (this.field.field_type == "radio" || this.field.field_type == "select") {
            this.field.options.push({value: "0", text: ""}, {value: "1", text: ""})
          } else {
            this.field.options = []
          }
        }
      },
      addOption() {
        if (this.field.field_type == "radio" || this.field.field_type == "select") {
          if (this.field.options[this.field.options.length -1].text == 'Others'){
            this.field.options.splice(this.field.options.length - 1, 0, {value: (this.field.options.length - 1).toString(), text: ""})
          } else {
            this.field.options.push({value: this.field.options.length.toString(), text: ""})
          }
        }
      },
      deleteOption(index) {
        this.field.options.splice(index, 1)
      },
      closeSetting() {
        // this.selectedIndex = null
        this.$emit('closeSetting')
      },
      validOptionLabel(label) {
        if (_.isEmpty(label)) {
          return ["cannot be blank"]
        }
      }
    },
    computed: {
      fieldTypeName() {
        const name = this.fieldTypeList.find( obj => {
          return this.field.field_type == obj.value
        })
        return name.text
      },
      invalidLabel() {
        return this.field.field_type != 'divider' && (_.isEmpty(this.field.label) && this.selectedIndex != this.field.index)
      },
      invalidOptions() {
        if ((this.field.field_type == "radio" || this.field.field_type == "select") && this.selectedIndex != this.field.index) {
          for (let i=0; i < this.field.options.length; i++) {
            if (this.field.options[i].text == "") {
              return true
            }
          }
        }
      },
    }
  }
</script>

<style scooped>
  .setting {
    margin: 0rem 6rem;
    padding: 1rem 2rem;
    background: #E4E7EC;
    border: darkgray solid 1px;
    border-radius: 12px;
  }
</style>