<template>
  <modal-form v-model="contact" action="contact" modelName="contact"
              submitText="Submit"
              :showModal="showModal"
              @close="showModal = false"
              @success="onSuccess"
  >
    <template v-slot:header>
      <h4>Tell us what you need</h4>
      <p>We'll get back to you in 48 hours or call us at (+65) 9684 8620</p>
    </template>
    <div class="row">
      <div class="col s12 m4">
        <materialize-input label="Your Company" v-model="contact.company" :errors="contact.errors.company" placeholder="ACME Pte. Ltd." :required="true" />
        <materialize-input label="Your Name" v-model="contact.name" :errors="contact.errors.name" placeholder="John Smith" :required="true" />
        <materialize-input label="Email" v-model="contact.email" :errors="contact.errors.email" placeholder="rick@morty.com" :required="true" />
        <materialize-input label="Phone Number" v-model="contact.phone_number" :errors="contact.errors.phone_number" placeholder="91238888" />
      </div>
      <div class="col s12 m8">
        <materialize-input label="Your Message" v-model="contact.message" :errors="contact.errors.message" type="textarea" placeholder="Hi!" :required="true" />
      </div>
    </div>
  </modal-form>
</template>

<script>
  import ModalForm from '../components/modal_form.vue';
  import MaterializeInput from '../components/materialize_input.vue';

  export default {
    components: { ModalForm, MaterializeInput },
    data() {
      return {
        showModal: false,
        contact: { company: '', name: '', email: '', phone_number: '', message: '', errors: {} },
      }
    },
    methods: {
      openModal() {
        this.showModal = true;
      },
      onSuccess() {
        this.showModal = false;
        swal({
          title: "We've received your message and will get back to you soon!",
          confirmButtonColor: '#eebe84'
        })
      }
    }
  }
</script>
