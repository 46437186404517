import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

Sentry.init({
  dsn: process.env.NODE_ENV === 'production' ? 'https://65be6d2c0d3b438aad628fc901f08211@sentry.io/184921' : false,
  integrations: [new Integrations.Vue({ Vue, attachProps: true })],
  environment: SENTRY_ENVIRONMENT,
  serverName: HOSTNAME,
});

document.addEventListener('turbolinks:load', () => {
  Sentry.setUser(Object.assign({}, document.body.dataset));
});
