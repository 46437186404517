<template>
  <select class="selectize manual-init" :disabled="disabled ? 'disabled' : false"
    ><slot></slot
  ></select>
</template>

<script>
import * as Sentry from '@sentry/browser';

export default {
  props: {
    // The currently selected value. You should use `v-model` instead of `value` directly for two-way binding.
    value: {
      required: true,
    },
    // The select <option>s in selectize format, which is an array of objects: `[{ value: 'value', text: 'text' }, ...]`.
    options: {
      type: Array,
      required: true,
    },
    // The placeholder text to show if no option is selected.
    placeholder: {
      type: String,
      default: 'Select an option',
    },
    // Setting to `true` will disable the select input
    disabled: {
      type: Boolean,
      default: false,
    },
    // Setting to `true` will cause the current selection to be cleared again immediately
    // after any option is selected. Useful for transiently choosing something.
    clearAfterSelect: {
      type: Boolean,
      default: false,
    },
    // Setting to `true` will cause the current selection to be cleared after it is clicked
    // and the dropdown is opened. Useful for filterrific selections.
    clearAfterOpen: {
      type: Boolean,
      default: false,
    },
    // Allows easy binding of the select options to a remote URL. Requires `remoteParams`
    // to be set as well. The remote URL should return an JSON array of objects.
    remotePath: {
      type: String,
    },
    // The values to be watched and sent to the `remotePath` in order to get new select options
    remoteParams: {
      type: Object,
    },
    // The key for each object in the remote response array to use the option's value.
    remoteValueKey: {
      type: String,
      default: 'id',
    },
    // The key for each object in the remote response array to use as the option's label text.
    remoteLabelKey: {
      type: String,
      default: 'name',
    },
    // Additiopnal options to pass directly to the selectize initializer
    additionalOptions: {
      type: Object,
      default: () => {},
    },
    isMultiSelect: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {
    this.isMultiSelect = !!this.$el.getAttribute('multiple');
    $(this.$el).selectize(
      Object.assign(
        {
          plugins: this.isMultiSelect ? ['remove_button'] : [],
          selectOnTab: this.isMultiSelect,
          closeAfterSelect: !this.isMultiSelect,
          options: this.options,
          placeholder: this.placeholder,
          onInitialize: () => {
            this.$nextTick(() => this.addItems(this.value, true));
            if (this.remotePath) this.fetchItems(this.remoteParams);
          },
          onChange: (val) => {
            this.clearItems();
            this.$emit('input', val);
            if (this.clearAfterSelect) {
              setTimeout(() => {
                this.$el.selectize.clear(true);
              }, 200);
            }
          },
          onDropdownOpen: () => {
            if (this.clearAfterOpen) {
              this.$el.selectize.clear();
            }
          },
          onDropdownClose: () => {
            // this.$el.selectize.blur();
          },
        },
        this.additionalOptions
      )
    );
  },
  watch: {
    value(value) {
      this.addItems(value);
    },
    options(value) {
      const originalValues = this.value;
      const s = this.$el.selectize;
      s.clear();
      s.clearOptions();
      s.load((callback) => callback(value));
      s.addItems(originalValues);
    },
    remoteParams(value) {
      this.fetchItems(value);
    },
  },
  methods: {
    addItems(items, silent = false) {
      const s = this.$el.selectize;
      if (!s) return; // not yet initialized
      if (!items && items !== 0) {
        s.clear(true);
      } else if (this.isMultiSelect) {
        items.forEach((item) => s.addItem(item, silent));
      } else {
        s.addItem(items, silent);
      }
    },
    clearItems() {
      const selected = $('div.option');
      for (let i = 0; i < this.options.length; i++) {
        $(selected).removeClass('selected');
      }
    },
    fetchItems(params) {
      if (!this.remotePath) {
        console.error('remotePath must be specified if remoteParams is specified!');
        return;
      }

      // save original selected options, then disable
      const originalValues = this.value;
      const s = this.$el.selectize;
      s.disable();
      if (!params || params === '') {
        s.clearOptions();
        return;
      }

      // load new options
      s.load((callback) => {
        this.xhr && this.xhr.abort();
        this.xhr = $.ajax({
          url: this.remotePath,
          data: params,
          success: (results) => {
            results = results.map((i) => {
              return {
                ...i,
                value: this.remoteValueKey ? i[this.remoteValueKey] : i.value,
                text: this.remoteLabelKey ? i[this.remoteLabelKey] : i.text,
              };
            });
            s.clearOptions();
            callback(results);
            this.addItems(originalValues);
            if (results.length === 0) s.disable();
            else s.enable();
          },
          error(xhr, exception) {
            if (exception !== 'abort') {
              // Only clear options if this is not a manual xhr abort
              console.error(exception);
              s.clearOptions();
              callback();
            }
          },
        });
      });
    },
  },
  destroyed() {
    this.$el.selectize.destroy();
  },
};
</script>
