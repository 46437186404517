<template>
    <div class="row">
        <div class="col s12" :class="{ 'radio_buttons': isHorizontal }">
            <materialize-checkbox
                    v-for="option in options"
                    :key="option.value"
                    :inputClass="inputClass"
                    :labelClass="labelClass"
                    :labelStyle="labelStyle"
                    :label="option.text"
                    :type="type"
                    :disabled="disabled"
                    :isHorizontal="isHorizontal"
                    :value="value === option.value"
                    @input="(checked) => { checked && $emit('input', option.value) }"
            ></materialize-checkbox>
        </div>
    </div>
</template>
<script>
  import MaterializeCheckbox from './materialize_checkbox';

  export default {
    components: { MaterializeCheckbox },
    props: {
      // The input value.
      value: {},
      // The input options as an <Array> of <Object>.
      // Each <Object> should contain the `value` and `text` keys which define
      // the underlying value and displayed text for each option respectively
      options: {
        required: true,
      },
      // The errors for this input.
      errors: {
        type: Array,
      },
      // The HTML type of checkbox to use. Can only be `radio` or `checkbox`.
      type: {
        type: String,
        default: 'radio'
      },
      // The HTML class to use for the checkbox labels.
      labelClass: {
        type: String,
      },
      // The HTML class to use for the checkbox labels.
      labelStyle: {
        type: String,
      },
      // The HTML class to use for the checkbox input.
      inputClass: {
        type: String,
      },
      // Whether the checkboxes should be laid out horizontally or vertically.
      isHorizontal: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
      },
      required: {
        type: Boolean,
        default: true,
      }
    },
    mounted() {
      this.autoSelectOptionIfRequired();
    },
    watch: {
      options() {
        this.autoSelectOptionIfRequired()
      }
    },
    methods: {
      autoSelectOptionIfRequired() {
        if (this.required == true && !this.options.find((o) => o.value === this.value) && this.options.length > 0) {
          this.$emit('input', this.options[0].value);
        }
      }
    }
  }
</script>

<style>
    .input-field.col .radio_buttons label {
        left: auto;
        top: 3rem;
    }
</style>
