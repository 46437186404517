function modelHasErrors(model) {
  /* eslint-disable no-continue, no-restricted-syntax */
  for (const [key, value] of Object.entries(model)) {
    // Only check Object types
    if (!value || typeof (value) !== 'object') continue;

    // If it is the `errors` object..
    if (key === 'errors') {
      // Check whether there is anything set
      for (const errors in Object.values(value)) {
        if (errors && errors.length > 0) return true;
      }
    } else if (modelHasErrors(value)) {
      // Otherwise, the object is a nested model, so recurse on it
      return true;
    }
  }

  return false;
}

// Returns a new Object for the model with only the `error` keys updated.
// If `setMissingKeys` is `true`, also set keys on the original object that were null or undefined.
function updateModelWithErrors(originalModel, modelWithErrors, setMissingKeys = false) {
  // Handle null
  if (modelWithErrors === null) {
    return null;
  }

  // Handle primitives
  if (typeof (modelWithErrors) !== 'object') {
    return modelWithErrors;
  }

  // Handle Arrays
  if (Array.isArray(modelWithErrors)) {
    const updatedModel = (Array.isArray(originalModel) ? originalModel.slice(0, modelWithErrors.length) : []);
    for (let i = 0; i < modelWithErrors.length; i += 1) {
      updatedModel[i] = updateModelWithErrors(originalModel[i], modelWithErrors[i], setMissingKeys);
    }
    return updatedModel;
  }

  // Handle Objects
  const updatedModel = Object.assign({}, originalModel);
  if (updatedModel.errors) updatedModel.errors = []; // clear old errors
  for (const [key, value] of Object.entries(modelWithErrors)) {
    if (key === 'errors' || (setMissingKeys && !updatedModel[key])) {
      updatedModel[key] = value;
    } else if (value && typeof (value) === 'object') {
      updatedModel[key] = updateModelWithErrors((originalModel && originalModel[key]) || {}, modelWithErrors[key], setMissingKeys);
    }
  }
  return updatedModel;
}

function removeModelErrors(model) {
  // Handle null
  if (model === null) {
    return null;
  }

  // Handle primitives
  if (typeof (model) !== 'object') {
    return model;
  }

  // Handle Arrays
  if (Array.isArray(model)) {
    const newArray = [];
    for (let i = 0; i < model.length; i += 1) {
      newArray[i] = removeModelErrors(model[i]);
    }
    return newArray;
  }

  // Handle Objects
  const newObject = {};
  for (const [key, value] of Object.entries(model)) {
    if (key === 'errors') {
      newObject[key] = [];
    } else {
      newObject[key] = removeModelErrors(value);
    }
  }
  return newObject;
}

function successToast(message = 'Action successful') {
  if (window.Materialize) window.Materialize.toast(message, 4000, 'green darken-4');
}

function errorToast(error = 'Sorry, something went wrong and we have been informed of your issue. Please try again later.') {
  if (window.Materialize) window.Materialize.toast(error, 4000, 'red');
}

module.exports = { modelHasErrors, updateModelWithErrors, removeModelErrors, errorToast, successToast };
