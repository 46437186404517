<template>
  <input type="text" :placeholder="placeholder" :value="value" @input="onInput">
</template>

<script>
  const Flatpickr = require("flatpickr");
  import ConfirmDatePlugin from "flatpickr/dist/plugins/confirmDate/confirmDate";

  // See: https://github.com/vuwe/vue-flatpickr/blob/2.0/src/components/vue-flatpickr.vue
  export default {
    props: {
      placeholder: {
        type: String,
        default: 'Select or type a date (DD/MM/YYYY)'
      },
      options: {
        type: Object,
        default: (() => ({}))
      },
      value: {
        default: ''
      }
    },
    data() { return { fp: null }; },
    computed: {
      fpOptions() {
        return JSON.stringify(this.options);
      }
    },
    watch: {
      fpOptions(newOptions) {
        const options = JSON.parse(newOptions);
        for (const [key, value] of Object.entries(options)) {
          if (key === 'mode') {
            // Need to reinitialize if mode changes,
            // see issue here: https://github.com/chmln/flatpickr/issues/1036
            this.destroy();
            this.initialize();
            return;
          }
          this.fp.set(key, value)
        }
        this.fp.redraw();
      },
      value(val) {
        this.fp.setDate(val, false);
      }
    },
    mounted() {
      this.initialize();
      this.$emit('mounted', this.fp);
    },
    destroyed() {
      this.destroy();
    },
    methods: {
      initialize() {
        this.fp = new Flatpickr(this.$el, Object.assign(
          // defaults
          {
            allowInput: true,
            altInput: true,
            altFormat: this.options.noCalendar ? 'h:i K' : `j M Y${this.options.enableTime ? ', h:i K' : ''}`,
          },

          // props
          this.options,

          // overrides
          {
            parseDate(d) {
              const date = moment(d, ["DD-MM-YYYY", "YYYY-MM-DD", "hh:mm A", "DD MMM YYYY"]);
              return date.isValid() ? date.toDate() : null;
            },
            onChange(selDates, dateStr) {
              // Save the string representation of the last selected date.
              this._selDateStr = dateStr;
            },
            onClose() {
              // Update the manually input value without needing to press the enter key:
              if (this.config.allowInput && this._input.value !== this._selDateStr) {
                if (this._input.value) {
                  const val =
                    (this.config.mode === 'range' && this._input.value.split(' to ').length < 2) ?
                      `${this._input.value} to ${this._input.value}` : this._input.value
                  this.setDate(val, true, 'j-n-Y');
                } else {
                  this.setDate(this._selDateStr);
                }
              }
            },
            plugins: [new ConfirmDatePlugin({ showAlways: true })]
          })
        );
        this.fp.setDate(this.value, false);
      },
      destroy() {
        this.fp.destroy();
        this.fp = null;
      },
      onInput(e) {
        if (typeof e === 'string') {
          this.$emit('input', e);
        } else {
          this.$emit('input', e.target.value);
        }
      }
    }
  }
</script>

<style src="flatpickr/dist/themes/airbnb.css"></style>
<style src="flatpickr/dist/plugins/confirmDate/confirmDate.css"></style>
<style lang="scss">
  @import '~colors';

  .flatpickr-month {
    height: 3rem;
  }

  .flatpickr-day.today {
    border-color: $primary-600;
  }

  span.flatpickr-day.today:not(.selected), span.flatpickr-day.prevMonthDay.today:not(.selected), span.flatpickr-day.nextMonthDay.today:not(.selected) {
    border-bottom-color: $primary-600;
  }

  span.flatpickr-day.today:not(.selected):hover, span.flatpickr-day.prevMonthDay.today:not(.selected):hover, span.flatpickr-day.nextMonthDay.today:not(.selected):hover {
    border: 1px solid $primary-600;
    background: $primary-600;
  }

  .flatpickr-prev-month:hover, .flatpickr-next-month:hover {
    color: $primary-600;
  }

  .flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange,
  .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange,
  .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus,
  .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day:hover,
  .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay,
  .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
    background: $primary-50;
    color: $primary-600;
  }

  .noCalendar .flatpickr-time {
    border-bottom: 1px solid $primary-600;
    box-shadow: 0 1px 0 0 $color-secondary;
  }
  .numInput:focus {
    border: none !important;
    background: rgba(0,0,0,0.09) !important;
  }
  .numInput:hover:not(:focus) {
    background: rgba(0,0,0,0.04);
  }
  .numInputWrapper:hover {
    background: none;
  }
</style>
