function getData(url, data = {}) {
  return $.ajax({
    url,
    method: 'GET',
    dataType: 'json',
    contentType: 'application/json',
    data
  })
}

function postData(url, data = {}) {
  return $.ajax({
    url,
    method: "POST",
    dataType: 'json',
    contentType: 'application/json',
    data: JSON.stringify(data)
  })
}

function patchData(url, data = {}) {
  return $.ajax({
    url,
    method: "PATCH",
    dataType: 'json',
    contentType: 'application/json',
    data: JSON.stringify(data)
  })
}

function deleteData(url) {
  return $.ajax({
    url,
    method: "DELETE",
    dataType: 'json',
    contentType: 'application/json'
  })
}

export {
  getData,
  postData,
  patchData,
  deleteData
};