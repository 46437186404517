// This file is automatically compiled by Webpack, along with any other files
// present in this directory. This pack is used for public pages in the home controller.
//
// This file is referenced by adding <%= javascript_pack_tag 'public' %> to the
// layout file in app/views/layouts/application.html.erb

import "core-js/stable";
import "regenerator-runtime/runtime";
import 'lity';
import 'lity/dist/lity.min.css';
import Vue from 'vue';
import '../styles/tooltip.scss';
import '../util/setup';
import Tooltip from 'tooltip.js/dist/esm/tooltip';
import VueScrollTo from 'vue-scrollto';
import VModal from 'vue-js-modal'
import VTooltip from 'v-tooltip';
import JobApplicationsForm from '../forms/job_applications.vue';
import VisitorsForm from '../forms/visitors/visitors_form.vue';

import ContactUsForm from '../forms/contact_us.vue';
import JssCalculator from '../forms/jss_calculator.vue';
import "channels";

// import RegisterForm from '../forms/register.vue';


Vue.use(VueScrollTo); // auto-scroll to element on page
Vue.use(VTooltip, { delay: 0, defaultClass: 'payboy-tooltip' });
Vue.use(VModal)
window.Tooltip = Tooltip;

document.addEventListener('turbolinks:load', () => {
  if (document.getElementById('contact-us-form')) {
    const contactUsForm = new Vue(ContactUsForm).$mount('#contact-us-form');
    document.getElementById('contact-us-modal-trigger').addEventListener('click', () => {
      contactUsForm.openModal();
    });
  } else if (document.getElementById('job-applications-form')) {
    new Vue({ render: h => h(JobApplicationsForm) }).$mount('#job-applications-form');
  } else if (document.getElementById('visitors-form')) {
    new Vue({ render: h => h(VisitorsForm) }).$mount('#visitors-form');
  } else if (document.getElementById('jss-calculator')) {
    new Vue({ render: h => h(JssCalculator) }).$mount('#jss-calculator');
  };
});

//Confirm modal for Job Application Upload Documents remove button
(function() {
  window.swalConfirm = function(title, text) {
    if (text == null) {
      text = '';
    }
    return swal({
      title: title,
      text: text,
      type: 'warning',
      confirmButtonText: 'OK',
      confirmButtonColor: '#5d7885',
      showCancelButton: true
    });
  };

}).call(this);