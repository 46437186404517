<template>
  <div class="section">
    <div class="row">
      <div class="step-1" style="margin-bottom: 50px;">
        <h3 class="header-text">Step 1</h3>
        <hr>
        <div class="step-1-content" >
          <div class="select-container">
            <label class="select-label">Payout Month:</label>
            <select class="select" v-model="selectedPayoutMonth" v-on:change="this.updateTotal">
              <!-- <option value="" disabled>Select payout month</option> -->
              <option
                v-for="payoutMonthOption in payoutMonthOptions"
                :key="payoutMonthOption"
                :disabled="((selectedPayoutTier == 'Tier 3A' || selectedPayoutTier == 'Tier 3B') &&
                  (payoutMonthOption == 'Apr 2020' || payoutMonthOption == 'May 2020' || payoutMonthOption == 'Jul 2020' || payoutMonthOption == 'Oct 2020')) ||
                  (selectedPayoutTier == 'Tier 3' && (payoutMonthOption == 'Mar 2021' || payoutMonthOption == 'Jun 2021')) || (selectedPayoutTier == 'Tier 3B' && payoutMonthOption == 'Jun 2021')">
                  {{payoutMonthOption}}
                </option>
            </select>
          </div>
          <div class="select-container">
            <label class="select-label">Payout Tier:</label>
            <select class="select" v-model="selectedPayoutTier" v-on:change="this.updateTotal" :disabled="selectedPayoutMonth == 'May 2020'">
              <!-- <option value="">Select payout tier</option> -->
              <option
                v-for="tierOption in tierOptions"
                :key="tierOption"
                :disabled="((tierOption == 'Tier 3A' || tierOption == 'Tier 3B') &&
                  (selectedPayoutMonth == 'Apr 2020' || selectedPayoutMonth == 'Jul 2020' || selectedPayoutMonth == 'Oct 2020')) ||
                  (tierOption == 'Tier 3' && selectedPayoutMonth == 'Mar 2021') || (tierOption == 'Tier 3B' && selectedPayoutMonth == 'Jun 2021')">
                  {{tierOption}}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="step-2" style="margin-bottom: 50px;">
        <h3 class="header-text">Step 2</h3>
        <hr>
        <div class="table-container">
        <hot-table
            ref="hotTable"
            class="hot-table"
            :data="data"
            :settings="hotSettings"
            licenseKey="non-commercial-and-evaluation">
        </hot-table>
        </div>
      </div>

      <div class="step-3" style="margin-bottom: 50px;">
        <h3 class="header-text">Result</h3>
        <hr>
        <div class="step-3-content">
          <h5><strong>Total Gross Salary:</strong> ${{this.totalGrossSalary}}</h5>
          <br>
          <br>
          <h5><strong>{{this.hotSettings.colHeaders[0]}} Eligible Gross Salary</strong> ${{this.colATotal}}</h5>
          <br>
          <h5 v-if='this.hotSettings.colHeaders[1] != null'><strong>{{this.hotSettings.colHeaders[1]}} Eligible Gross Salary</strong> ${{this.colBTotal}}</h5>
          <br>
          <h5 v-if='this.hotSettings.colHeaders[2] != null'><strong>{{this.hotSettings.colHeaders[2]}} Eligible Gross Salary</strong> ${{this.colCTotal}}</h5>
          <br>
          <h5 v-if='this.hotSettings.colHeaders[3] != null'><strong>{{this.hotSettings.colHeaders[3]}} Eligible Gross Salary</strong> ${{this.colDTotal}}</h5>
          <br>
          <br>
          <h5><strong>{{this.hotSettings.colHeaders[0]}} Support ({{this.percentage[0]}}):</strong> ${{this.supportAmount[0]}}</h5>
          <br>
          <h5 v-if='this.hotSettings.colHeaders[1] != null'><strong>{{this.hotSettings.colHeaders[1]}} Support ({{this.percentage[1]}}):</strong> ${{this.supportAmount[1]}}</h5>
          <br>
          <h5 v-if='this.hotSettings.colHeaders[2] != null'><strong>{{this.hotSettings.colHeaders[2]}} Support ({{this.percentage[2]}}):</strong> ${{this.supportAmount[2]}}</h5>
          <br>
          <h5 v-if='this.hotSettings.colHeaders[3] != null'><strong>{{this.hotSettings.colHeaders[3]}} Support ({{this.percentage[3]}}):</strong> ${{this.supportAmount[3]}}</h5>
          <br>
          <br>
          <h5><strong>Total JSS Payout:</strong> ${{this.totalJSSPayout}}</h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { HotTable } from '@handsontable/vue';
  import Handsontable from 'handsontable';
    export default {
        data() {
            return {
              payoutMonthOptions: [ 'Apr 2020', 'May 2020', 'Jul 2020', 'Oct 2020', 'Mar 2021' , 'Jun 2021'],
              tierOptions: [ 'Tier 1', 'Tier 2', 'Tier 3', 'Tier 3A', 'Tier 3B' ],
              selectedPayoutMonth: "Apr 2020",
              selectedPayoutTier: "Tier 1",
              totalGrossSalary: 0,
              totalJSSPayout: 0,
              colATotal: 0.0,
              colBTotal: 0.0,
              colCTotal: 0.0,
              colDTotal: 0.0,
              payoutPercentage: {
                'Tier 1': {'Apr 2020': [0.75, 0.75, 0.75, 0], 'May 2020': [0.75, 0, 0, 0], 'Jul 2020': [0.75, 0.75, 0.75, 0], 'Oct 2020': [0.75, 0.75, 0.75, 0.75], 'Mar 2021': [0.5, 0.5, 0.5, 0.5], 'Jun 2021': [0.5, 0.5, 0.5, 0]},
                'Tier 2': {'Apr 2020': [0.75, 0.5, 0.5, 0], 'May 2020': [0.75, 0, 0, 0], 'Jul 2020': [0.5, 0.5, 0.5, 0], 'Oct 2020': [0.5, 0.5, 0.5, 0.5], 'Mar 2021': [0.3, 0.3, 0.3, 0.3], 'Jun 2021': [0.3, 0.3, 0.3, 0]},
                'Tier 3': {'Apr 2020': [0.75, 0.25, 0.25, 0], 'May 2020': [0.75, 0, 0, 0], 'Jul 2020': [0.25, 0.25, 0.25, 0], 'Oct 2020': [0.25, 0.25, 0.25, 0.25]},
                'Tier 3A': {'Apr 2020': [0.75, 0.25, 0.25, 0], 'May 2020': [0.75, 0, 0, 0], 'Jul 2020': [0.25, 0.25, 0.25, 0], 'Oct 2020': [0.25, 0.25, 0.25, 0.25], 'Mar 2021': [0.1, 0.1, 0.1, 0.1], 'Jun 2021': [0.1, 0.1, 0.1, 0]},
                'Tier 3B': {'Apr 2020': [0.75, 0.25, 0.25, 0], 'May 2020': [0.75, 0, 0, 0], 'Jul 2020': [0.25, 0.25, 0.25, 0], 'Oct 2020': [0.25, 0.25, 0.25, 0.25], 'Mar 2021': [0.1, 0.1, 0.1, 0.1]}
              },
              percentage: ['75%', '25%', '25%', '0'],
              supportAmount: [0, 0, 0, 0],
              data: [],
              hotSettings: {
                colKeys: ['a', 'b', 'c', 'd'],
                colHeaders: this.colHeaders,
                rowHeaders: true,
                minRows: 300,
                // minSpareRows: 10,
                width: '400px',
                height: '55vh',
                colWidth: 300,
                columns: [
                  {data: 'a', type: 'numeric', editor: 'numeric', numericFormat: {pattern: '$0,0.00'}},
                  {data: 'b', type: 'numeric', editor: 'numeric', numericFormat: {pattern: '$0,0.00'}},
                  {data: 'c', type: 'numeric', editor: 'numeric', numericFormat: {pattern: '$0,0.00'}},
                  {data: 'd', type: 'numeric', editor: 'numeric', numericFormat: {pattern: '$0,0.00'}},
                ],
                afterChange: this.afterChangeVue
              },
            };
        },
    components: {
      HotTable
    },
    watch: {
      'selectedPayoutMonth'() {
        this.colHeaders()
        this.totalWages()
      }
    },
    methods: {
        afterChangeVue(change, source) {
          this.updateTotal()
        },
        colHeaders() {
          this.hotSettings.columns = [
            {data: 'a', type: 'numeric', editor: 'numeric', numericFormat: {pattern: '$0,0.00'}},
            {data: 'b', type: 'numeric', editor: 'numeric', numericFormat: {pattern: '$0,0.00'}},
            {data: 'c', type: 'numeric', editor: 'numeric', numericFormat: {pattern: '$0,0.00'}},
          ]
          if (this.selectedPayoutMonth == "Apr 2020") {
            this.hotSettings.colHeaders = ['Oct 2019', 'Nov 2019', 'Dec 2019']
          } else if (this.selectedPayoutMonth == "Jul 2020") {
            this.hotSettings.colHeaders = ['Feb 2020', 'Mar 2020', 'Apr 2020']
          } else if (this.selectedPayoutMonth == "Oct 2020") {
            this.hotSettings.colHeaders = ['May 2020', 'Jun 2020', 'Jul 2020', 'Aug 2020']
            this.hotSettings.columns = [
              {data: 'a', type: 'numeric', editor: 'numeric', numericFormat: {pattern: '$0,0.00'}},
              {data: 'b', type: 'numeric', editor: 'numeric', numericFormat: {pattern: '$0,0.00'}},
              {data: 'c', type: 'numeric', editor: 'numeric', numericFormat: {pattern: '$0,0.00'}},
              {data: 'd', type: 'numeric', editor: 'numeric', numericFormat: {pattern: '$0,0.00'}},
            ]
          } else if (this.selectedPayoutMonth == "Mar 2021") {
            this.hotSettings.colHeaders = ['Sep 2020', 'Oct 2020', 'Nov 2020', 'Dec 2020']
            this.hotSettings.columns = [
              {data: 'a', type: 'numeric', editor: 'numeric', numericFormat: {pattern: '$0,0.00'}},
              {data: 'b', type: 'numeric', editor: 'numeric', numericFormat: {pattern: '$0,0.00'}},
              {data: 'c', type: 'numeric', editor: 'numeric', numericFormat: {pattern: '$0,0.00'}},
              {data: 'd', type: 'numeric', editor: 'numeric', numericFormat: {pattern: '$0,0.00'}},
            ]
          } else if (this.selectedPayoutMonth == "Jun 2021") {
            this.hotSettings.colHeaders = ['Jan 2021', 'Feb 2021', 'March 2021']
          } else {
            this.hotSettings.colHeaders = ['Nov 2019']
            this.hotSettings.columns = [
              {data: 'a', type: 'numeric', editor: 'numeric', numericFormat: {pattern: '$0,0.00'}},
            ]
            this.selectedPayoutTier = "Tier 1"
          }
        },
      totalWages() {
        let total = 0.0
        let colATotal = 0.0
        let colBTotal = 0.0
        let colCTotal = 0.0
        let colDTotal = 0.0
        this.data.forEach ( row => {
          colATotal += Math.min(parseFloat(row['a'] || 0), 4600)
          if (this.selectedPayoutMonth == "May 2020") {
            total += parseFloat(row['a'] || 0)
          } else if (this.selectedPayoutMonth == "Oct 2020" || this.selectedPayoutMonth == "Mar 2021") {
            colBTotal += Math.min(parseFloat(row['b'] || 0), 4600)
            colCTotal += Math.min(parseFloat(row['c'] || 0), 4600)
            colDTotal += Math.min(parseFloat(row['d'] || 0), 4600)
            total += parseFloat(row['a'] || 0) + parseFloat(row['b'] || 0) + parseFloat(row['c'] || 0) + parseFloat(row['d'] || 0)
          } else {
            colBTotal += Math.min(parseFloat(row['b'] || 0), 4600)
            colCTotal += Math.min(parseFloat(row['c'] || 0), 4600)
            total += parseFloat(row['a'] || 0) + parseFloat(row['b'] || 0) + parseFloat(row['c'] || 0)
          }
        })
        this.colATotal = colATotal
        this.colBTotal = colBTotal
        this.colCTotal = colCTotal
        this.colDTotal = colDTotal
        this.totalGrossSalary = total;
      },
      totalPayout() {
        let payoutRate = this.payoutPercentage[this.selectedPayoutTier][this.selectedPayoutMonth]
        this.percentage = []
        this.supportAmount = []
        if (!!payoutRate) {
          payoutRate.forEach ( rate => {
            if (rate == 0.75) {
              this.percentage.push('75%')
            } else if (rate == 0.5) {
              this.percentage.push('50%')
            } else if (rate == 0.3) {
              this.percentage.push('30%')
            } else if (rate == 0.25) {
              this.percentage.push('25%')
            } else if (rate == 0.1) {
              this.percentage.push('10%')
            } else {
              this.percentage.push('0%')
            }
          })
          this.supportAmount.push(this.colATotal * payoutRate[0])
          this.supportAmount.push(this.colBTotal * payoutRate[1])
          this.supportAmount.push(this.colCTotal * payoutRate[2])
          this.supportAmount.push(this.colDTotal * payoutRate[3])
          this.totalJSSPayout = this.supportAmount.reduce((a,b) => a + b, 0.0)
        }
      },
      updateTotal() {
        this.totalWages()
        this.totalPayout()
      }
    },
    computed: {

    }
  }
</script>

<style scoped lang="scss">
.section {
  padding: 30px;
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.header-text {
  text-align: center;
}
.step-1 {
  justify-content: stretch;
  width: 100%;
  padding: 20px;
  border: 1px solid #c6c6c6;
  border-radius: 12px;
  margin: 0 10px;
}
.step-1-content {
  display: flex;
  height: 90%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.select-container {
  display: flex;
  flex-direction: column;
}
.select {
  text-align: center;
  text-align-last: center;
  margin-top: 5px;
  margin-bottom: 30px;
  width: 300px;
  height: 40px;
  padding-right: 30px;
  border-color: #b8b8b8;
  font-family: 'Changa';
  color: #303030;
  font-size: 0.9rem;
}
.step-2 {
  text-align: center;
  width: 100%;
  padding: 20px;
  border: 1px solid #c6c6c6;
  border-radius: 12px;
  margin: 0 10px;
}
.table-container {
  text-align: center;
  margin-top: 20px;
}
.hot-table {
  display: inline-block;
  height: 500px;
}
.step-3 {
  width: 100%;
  border: 1px solid #c6c6c6;
  border-radius: 12px;
  padding: 20px;
  margin: 0 10px;
}
.step-3-content {
  display: flex;
  height: 90%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (max-width: 992px) {
  .row {
    display: flex;
    flex-direction: column;
    // justify-content: space-evenly;
  }
  .step-1, .step-2, .step-3 {
    margin-bottom: 30px;
  }
  .step-1-content {
    margin-top: 30px;
  }
}

@media (max-width: 768px) {
  .row {
    display: flex;
    flex-direction: column;
    // justify-content: space-evenly;
  }
}

@media (max-width: 375px) {
  .select {
    width: 250px;
  }
}
</style>
