<template>
    <div :class="[{ 'has-error': hasErrors, 'input-field': !isCheckbox && !isFile }, wrapperClass]">
        <label :for="`vue-${_uid}`" class="active" :class="labelClass" v-if="!isCheckbox && !isFile">
            <slot name="label">
                {{ label }}
                <span v-if="required">*</span>
            </slot>
        </label>
        <slot :id="`vue-${_uid}`">
            <template v-if="isDate">
                <flatpickr
                        :id="`vue-${_uid}`"
                        :class="inputClass"
                        :value="value"
                        :placeholder="placeholder"
                        :disabled="disabled"
                        :options="flatpickrOptions"
                        @change="$emit('change', $event)"
                        @input="$emit('input', $event)">
                </flatpickr>
            </template>
            <template v-else-if="isSelect">
                <selectize
                        :id="`vue-${_uid}`"
                        :value="value"
                        :placeholder="placeholder"
                        :disabled="disabled"
                        :multiple="multiple"
                        :class="inputClass"
                        :options="options"
                        :clearAfterSelect="selectizeOptions.clearAfterSelect"
                        :clearAfterOpen="selectizeOptions.clearAfterOpen"
                        :remotePath="selectizeOptions.remotePath"
                        :remoteParams="selectizeOptions.remoteParams"
                        :remoteValueKey="selectizeOptions.remoteValueKey"
                        :remoteLabelKey="selectizeOptions.remoteLabelKey"
                        :additionalOptions="selectizeOptions.additionalOptions"
                        @input="$emit('input', $event)">
                </selectize>
            </template>
            <template v-else-if="isCurrency">
                <currency-input
                        :id="`vue-${_uid}`"
                        :class="inputClass"
                        :value="value"
                        :required="required"
                        :disabled="disabled"
                        @change="$emit('change', $event)"
                        @input="$emit('input', $event)">
                </currency-input>
            </template>
            <template v-else-if="isFormatNumber">
                <number-input
                        :id="`vue-${_uid}`"
                        :class="inputClass"
                        :value="value"
                        :required="required"
                        :disabled="disabled"
                        :positiveOnly="true"
                        @change="$emit('change', $event)"
                        @input="$emit('input', $event)">
                </number-input>
            </template>
            <template v-else-if="isSwitch">
                <input  :id="`vue-${_uid}`"
                        :class="inputClass"
                        style='border-top-right-radius:0; border-bottom-right-radius:0; text-align:center; width:70px; cursor:pointer; caret-color:transparent;'
                        placeholder="Yes"
                        @click="$emit('click', $event)"
                        @change="$emit('change', $event)"
                        @input="$emit('input', $event)">
                </input>
                <input  :id="`vue-${_uid}`"
                        :class="inputClass"
                        style='border-top-left-radius:0; border-bottom-left-radius:0; margin-left:-5px; text-align:center; width:70px; cursor:pointer; caret-color:transparent;'
                        placeholder="No"
                        @click="$emit('click', $event)"
                        @change="$emit('change', $event)"
                        @input="$emit('input', $event)">
                </input>
            </template>
            <template v-else-if="isFile">
                <materialize-file
                        :value="value"
                        :buttonLabel="label"
                        :placeholder="placeholder"
                        :fileName="fileName"
                        :fileDelete="fileDelete"
                        :alwaysAllowRemove="fileRemoveAlwaysShown"
                        :disabled="disabled"
                        :required="required"
                        v-on:remove="$emit('remove')"
                        v-on:input="$emit('input', $event)"
                        v-on:update:fileName="$emit('update:fileName', $event)"
                        v-on:update:fileDelete="$emit('update:fileDelete', $event)">
                    <template v-slot:text>
                        <span v-if="hasErrors" class="error-block">{{ errors[0] }}</span>
                    </template>
                </materialize-file>
            </template>
            <template v-else-if="isCheckbox">
                <materialize-checkbox
                        :value="value"
                        :label="label"
                        :labelClass="labelClass"
                        :inputClass="inputClass"
                        :disabled="disabled"
                        @change="$emit('change', $event)"
                        @input="$emit('input', $event)">
                </materialize-checkbox>
            </template>
            <template v-else-if="isTextarea">
                <textarea class="max-width-100"
                          :class="inputClass"
                          :id="`vue-${_uid}`"
                          :value="value"
                          :placeholder="placeholder"
                          :required="required"
                          :readonly="disabled"
                          @change="$emit('change', $event.target.value)"
                          @input="$emit('input', $event.target.value)">
                </textarea>
            </template>
            <template v-else-if="isPostfix">
                <input :id="`vue-${_uid}`"
                       :value="value"
                       type="text"
                       :placeholder="placeholder"
                       :required="required"
                       :disabled="disabled"
                       class="postfixed"
                       @change="$emit('change', $event.target.value)"
                       @input="$emit('input', $event.target.value)" />
                <div class="postfix">
                  {{postfixValue}}
                </div>
            </template>
            <template v-else-if="positiveOnly">
                <input :id="`vue-${_uid}`"
                       :value="value"
                       :type="type"
                       :placeholder="placeholder"
                       :required="required"
                       :disabled="disabled"
                       :class="inputClass"
                       min="0"
                       @change="$emit('change', $event.target.value)"
                       @input="$emit('input', $event.target.value)" />
            </template>
            <template v-else>
                <input :id="`vue-${_uid}`"
                       :value="value"
                       :type="type"
                       :placeholder="placeholder"
                       :required="required"
                       :disabled="disabled"
                       :class="inputClass"
                       @change="$emit('change', $event.target.value)"
                       @input="$emit('input', $event.target.value)" />
            </template>
        </slot>
        <span v-if="hint" v-html="hint" class="help-block" />
        <span v-if="hasErrors && !isFile" class="error-block">{{ errors[0] }}</span>
    </div>
</template>

<script>
  import Flatpickr from '../components/flatpickr'
  import Selectize from '../components/selectize'
  import CurrencyInput from '../components/currency_input'
  import NumberInput from '../components/number_input'
  import MaterializeFile from '../components/materialize_file'
  import MaterializeCheckbox from '../components/materialize_checkbox'

  export default {
    components: { Flatpickr, Selectize, CurrencyInput, NumberInput, MaterializeFile, MaterializeCheckbox },
    props: {
      // The value for this input. Use v-model instead.
      value: {
      },
      // The HTML input type
      type: {
        type: String,
        default: 'text'
      },
      hint: {
        type: String,
      },
      // The errors for this input
      errors: {
        type: Array,
      },
      // Whether this is a required input
      required: {
        type: Boolean
      },
      // Whether the input is disabled or not
      disabled: {
        type: Boolean
      },
      // The label text for this input
      label: {
        type: String
      },
      // The HTML class for this input's label
      labelClass: {
        type: String
      },
      // The HTML class for this input
      inputClass: {
        type: String
      },
      // The HTML class for the input's wrapper
      wrapperClass: {
        type: String
      },
      // The placeholder text for this input
      placeholder: {
        type: String
      },
      // The file name for the `file` input types
      fileName: {
        type: String
      },
      // Whether the file is being removed for `file` input types,
      fileDelete: {
        type: String
      },
      // Whether the remove button on `file` input types is always shown
      fileRemoveAlwaysShown: {
        type: Boolean,
        default: false
      },
      // The HTML `multiple` attribute
      multiple: {
        type: String,
      },
      // The HTML `option`s for `select` types in selectize format, which is an array of objects: `[{ value: 'value', text: 'text' }, ...]`.
      options: {
        type: Array,
        default: () => ([])
      },
      // Additional options for `select` input types
      selectizeOptions: {
        type: Object,
        default: () => ({})
      },
      // Additional options for `date` input types
      flatpickrOptions: {
        type: Object,
        default: () => ({})
      },
      // Value display in postfix div tag
      postfixValue: {
        type: String
      },
      positiveOnly: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        isTextarea: this.type === 'textarea',
        isDate: this.type === 'date',
        isCurrency: this.type === 'currency',
        isFile: this.type === 'file',
        isSelect: this.type === 'select',
        isRadio: this.type === 'radio',
        isCheckbox: this.type === 'checkbox',
        isFormatNumber: this.type === 'formatnumber',
        isPostfix: this.type === 'postfix',
        isSwitch: this.type === "switch",
      };
    },
    computed: {
      hasErrors() { return this.errors && this.errors.length > 0; }
    }
  };
</script>

<style scoped>
.max-width-100 {
  max-width: 100%;
}
</style>
